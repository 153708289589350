import {service} from "../helpers/request"

function UserList(page){
    return service({
        url:'/User/ListWithPage?page='+page,
        method:'post',
        data:{}
    })
}

function DoIncludedReport(id){
    return service({
        url:'/User/DoIncludedInPaymentReportForTeacher?id='+id,
        method:'post',
        data:{}
    })
}

function DoExcludedReport(id){
    return service({
        url:'/User/DoExcludedInPaymentReportForTeacher?id='+id,
        method:'post',
        data:{}
    })
}

function DoActive(id){
    return service({
        url:'/User/DoActive?id='+id,
        method:'post',
        data:{}
    })
}

function DoInactive(id){
    return service({
        url:'/User/DoInactive?id='+id,
        method:'post',
        data:{}
    })
}

function Create(model){
    return service({
        url:'/User/Create',
        method:'post',
        data:{
            role: model.role,
            name: model.name,
            surname : model.surname,
            birthday : model.birthday,
            gender:model.gender,
            username : model.username,
            password:model.password,
            status:model.status,
            eMailAddress:model.eMailAddress,
            phoneNumber:model.phoneNumber
        }
    })
}

function CreateTeacher(model){
    return service({
        url:'/User/CreateTeacher',
        method:'post',
        data:{
            name: model.name,
            surname : model.surname,
            nickname : model.nickname,
            birthday : model.birthday,
            gender:model.gender,
            username : model.username,
            password:model.password,
            status:model.status,
            eMailAddress:model.emailAddress,
            phoneNumber:model.phoneNumber,
            skypeAddress:model.skypeAddress,
            //workBeginTime:model.workBeginTime,
            //workFinishTime:model.workFinishTime
        }
    })
}

function TeacherProfileUpdate(model){
    return service({
        url:'/User/TeacherProfileUpdate',
        method:'post',
        data:{
            id : model.id,
            name: model.name,
            surname : model.surname,
            nickname : model.nickname,
            birthday : model.birthday,
            gender : model.gender,
            status : model.status
        }
    })
}

function TeacherWorkTimeUpdate(model){
    return service({
        url:'/User/TeacherWorkTimeUpdate',
        method:'post',
        data:{
            id : model.id,
            startTime: model.startTime,
            finishTime : model.finishTime,
        }
    })
}

function TeacherAddWage(model){
    return service({
        url:'/User/TeacherAddWage',
        method:'post',
        data:{
            teacherId : model.teacherId,
            wage: model.wage,
        }
    })
}

function GetWageByTeacherId(id){
    return service({
        url:'/User/GetWageByTeacherId?id='+id,
        method:'post',
        data:{}
    })
}

function TeacherContactUpdate(model){
    return service({
        url:'/User/TeacherContactUpdate',
        method:'post',
        data:{
            id : model.id,
            skypeName: model.skypeName,
            phoneNumber : model.phoneNumber,
            emailAddress : model.emailAddress,
            address : model.address,
        }
    })
}

function TeacherList(page){
    return service({
        url:'/User/ListWithPageForTeacher?page='+page,
        method:'post',
        data:{}
    })
}

function ActiveTeacherList(){
    return service({
        url:'/User/GetActiveTeachers',
        method:'post',
        data:{}
    })
}
function ActiveUserList(){
    return service({
        url:'/User/GetActiveUsers',
        method:'post',
        data:{}
    })
}

function IncludedPaymentReporTeacherList(){
    return service({
        url:'/User/GetIncludedPaymentReporTeachers',
        method:'post',
        data:{}
    })
}

function GetUser(id){
    return service({
        url:'/User/GetById?id='+id,
        method:'post',
        data:{}
    })
}

function UpdateProfile(model){
    return service({
        url:'/User/UpdateProfile',
        method:'post',
        data:{
            id:model.id,
            role: model.role,
            name: model.name,
            surname : model.surname,
            birthday : model.birthday,
            gender:model.gender
        }
    })
}

function ChangePassword(id){
    return service({
        url:'/User/ChangePassword?id='+id,
        method:'post',
        data:{}
    })
}

function GetTeacherPaymentByTeacherId(id){
    return service({
        url:'/User/GetTeacherPaymentByTeacherId?id='+id,
        method:'post',
        data:{}
    })
}

function CalculatePriceByTeacherId(model){
    return service({
        url:'/User/CalculatePriceByTeacherId',
        method:'post',
        data:{
            teacherId:model.teacherId,
            beginDate:model.beginDate,
            finishDate:model.finishDate
        }
    })
}



function AddTeacherPayment(model){
    return service({
        url:'/User/AddTeacherPayment',
        method:'post',
        data:{
            teacherId:model.teacherId,
            startDate:model.startDate,
            finishDate:model.finishDate,
            detail:model.detail,
            totalPrice:model.totalPrice
        }
    })
}

function DoPaidTeacherPayment(model){
    return service({
        url:'/User/DoPaidTeacherPayment',
        method:'post',
        data:{
            id:model.id,
            teacherId:model.teacherId,
        }
    })
}

function GetAvailableHoursById(id){
    return service({
        url:'/User/GetAvailableHoursById?id='+id,
        method:'post',
        data:{}
    })
}

function SaveAvailableHoursById(model){
    return service({
        url:'/User/SaveAvailableHoursById',
        method:'post',
        data : {
            teacherId:model.teacherId,
            hours:model.hours
        }
    })
}

function GetAvailableHoursAllTeacher(){
    return service({
        url:'/User/GetAvailableHoursAllTeacher',
        method:'post',
        data : {}
    })
}
function GetUsersInQueue(){
    return service({
        url:'/User/GetUsersInQueue',
        method:'post',
        data : {}
    })
}


function DoInactiveDistrubuteForBaseOperator(id){
    return service({
        url:'/User/DoInactiveDistrubuteForBaseOperator?id='+id,
        method:'post',
        data:{}
    })
}

function DoInactiveDistrubuteForBaseSale(id){
    return service({
        url:'/User/DoInactiveDistrubuteForBaseSale?id='+id,
        method:'post',
        data:{}
    })
}

function DoInactiveDistrubuteForLevelTestSale(id){
    return service({
        url:'/User/DoInactiveDistrubuteForLevelTestSale?id='+id,
        method:'post',
        data:{}
    })
}

function DoActiveDistrubuteForBaseOperator(id){
    return service({
        url:'/User/DoActiveDistrubuteForBaseOperator?id='+id,
        method:'post',
        data:{}
    })
}

function DoActiveDistrubuteForBaseSale(id){
    return service({
        url:'/User/DoActiveDistrubuteForBaseSale?id='+id,
        method:'post',
        data:{}
    })
}

function DoActiveDistrubuteForLevelTestSale(id){
    return service({
        url:'/User/DoActiveDistrubuteForLevelTestSale?id='+id,
        method:'post',
        data:{}
    })
}

export {
    UserList,DoActive,DoInactive,Create,GetUser,UpdateProfile,ChangePassword,
    CreateTeacher,TeacherList,TeacherProfileUpdate,TeacherWorkTimeUpdate,
    TeacherContactUpdate,ActiveTeacherList,TeacherAddWage,GetWageByTeacherId,GetAvailableHoursById,SaveAvailableHoursById,
    GetTeacherPaymentByTeacherId,CalculatePriceByTeacherId,AddTeacherPayment,DoPaidTeacherPayment,
    GetAvailableHoursAllTeacher,DoExcludedReport,DoIncludedReport,IncludedPaymentReporTeacherList,ActiveUserList,GetUsersInQueue,
    DoInactiveDistrubuteForBaseOperator,DoInactiveDistrubuteForBaseSale,DoInactiveDistrubuteForLevelTestSale,
    DoActiveDistrubuteForBaseOperator,DoActiveDistrubuteForBaseSale,DoActiveDistrubuteForLevelTestSale

};
