<template>
  <div class="modal fade" id="UploadFormModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Form Yükle</h5>
            <button type="button" class="close" @click="CloseUploadFormModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <form enctype="multipart/form-data" ref="UploadForm">
                  <div class="input-group">
                    
                    <select class="custom-select col-4" v-model="formatType">
                      <option value="1">Format 1</option>
                      <option value="2">Format 2</option>
                    </select>
                    <div class="custom-file col-8">
                      <input type="file" class="custom-file-input" @change="onFileChange" :disabled="isLoading">
                      <label class="custom-file-label" for="exampleInputFile">Dosya Seçiniz</label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="table-responsive mt-3" style="height: 400px;">
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th width="250">Ad</th>
                    <th width="250">Telefon</th>
                    <th width="250">E-Posta</th>
                    <th width="250">UtmSource</th>
                  </tr>
                </thead>
                <tbody v-if="formList.length==0">
                <tr><td colspan="7" class="text-center">Lütfen form dosyasını yükleyiniz.</td></tr>
                </tbody>
                <tbody v-else>
                  <tr v-for="(f,index) in formList" :key="f">
                    <td><small>{{index+1}})&nbsp;{{f.name}} {{f.surname}}</small></td>
                    <td><small>{{f.phoneCountryCode}}-{{f.phoneNumber}}</small></td>
                    <td><small>{{f.eMail}}</small></td>
                    <td><small>{{f.utmSource}}<br>{{f.utmContent}}<br>{{f.utmCampaign}}</small></td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary pull-right" :disabled="formList.length==0||isLoading" @click="ImportForm()">Form Oluştur</button>
          </div>
        </div>
      </div>
    </div>
</template>
<style scoped>
  
</style>
<script>
import {mapActions} from "vuex";
import {IsShowErrorMessage} from "../helpers/error-message-helper";
export default {
  name: 'UploadFormModal',
  components: {  },
  props: {
    Visible:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {      
      formList:[],
      isLoading:false,
      formatType:1
    }
  },
  computed:{
  },
  methods:{
    ...mapActions([
      "FORM_IMPORTCVS"
    ]),
    CloseUploadFormModal(){
      window.$("#UploadFormModal").modal('hide');
      this.$emit("CloseUploadFormModal", false);
    },
    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
    clearUtm(value){
      if(value==''){
        return '';
      }else{
        return value.replace(/"/gi,'');
      }
    },
    parseName(value){
      let fullName=[];
      if(value==''){
        fullName.push('');
        fullName.push('');
      }else{
        let nameArr = value.replace(/"/gi,'').split(' ');
        switch (nameArr.length) {
          case 1:{
            fullName.push(nameArr[0]);
            fullName.push('');
            break;
          }
          case 2:{
            fullName.push(nameArr[0]);
            fullName.push(nameArr[1]);
            break;
          }
          default:{
            fullName.push(nameArr[0]);
            let surname = nameArr.slice(-1*(nameArr.length-1));
            fullName.push(surname.toString().replace(/,/gi,' '));
            break;
          }
        }
      }
      return fullName;
    },
    parsePhone(value){
      let fullPhone=[];
      if(value==''){
        fullPhone.push('');
        fullPhone.push('');
      }else{
        value = value.replace(/p:/gi,'');
        switch (value.length) {
          case 13:{
            if(value.substring(0,3)=='+90'){
              fullPhone.push('0090');
              fullPhone.push(value.substring(3,value.length));
            }else if(value.substring(0,4)=='+994'){
              fullPhone.push('00994');
              fullPhone.push(value.substring(4,value.length));
            }else if(value.substring(0,1)=='+'){
              fullPhone.push('');
              fullPhone.push('00'+value.substring(1,value.length));
            }
            break;
          }
          case 11:{
            if(value.substring(0,2)=='+5'){
              fullPhone.push('0090');
              fullPhone.push(value.substring(1,value.length));
            }if(value.substring(0,2)=='05'){
              fullPhone.push('0090');
              fullPhone.push(value.substring(1,value.length));
            }else{
              fullPhone.push('');
              fullPhone.push(value.substring(1,value.length));
            }
            break;
          }
          case 10:{
            if(value.substring(0,1)=='5'){
              fullPhone.push('0090');
              fullPhone.push(value);
            }else{
              fullPhone.push('');
              fullPhone.push(value.substring(1,value.length));
            }
            break;
          }
          default:{
            fullPhone.push('');
            fullPhone.push(value.substring(1,value.length));
            break;
          }
        }
      }

      return fullPhone;
    },
    onFileChange(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;

        if(files[0].type!='text/csv'){
          alert('*.csv formatında dosya yükleyebilirsniz.');
          return;
        }
        if(this.formatType===1){
          this.createInput(files[0]);
        }else{
          this.createInput2(files[0]);
        }
    },
    createInput(file) {
      let fileData='';
      let promise = new Promise((resolve) => {
        var reader = new FileReader();
        reader.onload = () => {
          resolve((fileData = reader.result));
        };
        reader.readAsText(file);
      });
      promise.then(
        () => {
          let data = [];
          let lines = fileData.split(/\r\n|\n/);
          lines.forEach(l => {
            data.push(l.split(/\t/));
          });
          
          if(data.length<2)
            return;

          let nameIndex = data[0].indexOf('full_name');
          let phoneIndex = data[0].indexOf('phone_number');
          let emailIndex = data[0].indexOf('email');
          let campaignIndex = data[0].indexOf('adset_name');
          let formIndex = data[0].indexOf('form_name');

          for (let i = 1; i < data.length-1; i++) {
            let fullName = this.parseName(data[i][nameIndex]);
            let name = fullName[0];
            let surname = fullName[1];

            let fullPhone = this.parsePhone(data[i][phoneIndex]);
            let phoneCountryCode = fullPhone[0];
            let phoneNumber = fullPhone[1];

            let email = data[i][emailIndex];
            let campaign = this.clearUtm(data[i][campaignIndex]);
            let form = this.clearUtm(data[i][formIndex]);
            this.formList.push({name:name,surname:surname,phoneCountryCode:phoneCountryCode,phoneNumber:phoneNumber,eMail:email,utmSource:'instagram.com',utmContent:form,utmCampaign:campaign});
          }

        },
        error => {
          if(this.IsShowErrorMessage(error)){
            this.$toast.error(error,{
                position : 'top',
                duration : 3000
            });
          }
        }
      );
    },
    createInput2(file) {
      let fileData='';
      let promise = new Promise((resolve) => {
        var reader = new FileReader();
        reader.onload = () => {
          resolve((fileData = reader.result));
        };
        reader.readAsText(file);
      });
      promise.then(
        () => {
          let data = [];
          let lines = fileData.split(/\r\n|\n/);
          lines.forEach(l => {
            data.push(l.split(/\t/));
          });
          
          if(data.length<2)
            return;

          let nameIndex = data[0].indexOf('adı_soyadı');
          let phoneIndex = data[0].indexOf('telefon_numarası');
          let emailIndex = data[0].indexOf('e-posta');

          for (let i = 1; i < data.length-1; i++) {
            let fullName = this.parseName(data[i][nameIndex]);
            let name = fullName[0];
            let surname = fullName[1];

            let fullPhone = this.parsePhone(data[i][phoneIndex]);
            let phoneCountryCode = fullPhone[0];
            let phoneNumber = fullPhone[1];

            let email = data[i][emailIndex];
            this.formList.push({name:name,surname:surname,phoneCountryCode:phoneCountryCode,phoneNumber:phoneNumber,eMail:email,utmSource:'TCLL_FB_IG Form',utmContent:'',utmCampaign:''});
          }

        },
        error => {
          if(this.IsShowErrorMessage(error)){
            this.$toast.error(error,{
                position : 'top',
                duration : 3000
            });
          }
        }
      );
    },
    ImportForm() {
      if(!confirm("Form listesini yüklemek istiyor musunuz?"))
        return;

      this.isLoading=true;
      this.FORM_IMPORTCVS(this.formList)
      .then(()=>{
         this.$toast.success("Form başarıyla yüklendi.",{
              position : 'top',
              duration : 3000
          });
          this.isLoading=false;
        }).catch(error=>{
          this.isLoading=false;
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
        });
    },
  },
  watch: {
    Visible(value){
      if(value){
        this.formList=[];
        this.$refs.UploadForm.reset();
        window.$("#UploadFormModal").modal({backdrop: 'static',keyboard: false, show: true});
      }
    }
  },
  mounted: function() {}
}
</script>