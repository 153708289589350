<template>
  <div class="modal fade" v-bind:id="ModalName" tabindex="2" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Ünite Değişikiği</h5>
            <button type="button" class="close" @click="onCloseBookChangeUnitModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-3 text-center text-sm"><span class="d-block text-bold">Tarih</span>{{ LessonDate }}</div>
              <div class="col-4 text-center text-sm"><span class="d-block text-bold">Kitap</span>{{BookName}}</div>
              <div class="col-5 text-center text-sm"><span class="d-block text-bold">Konu</span>{{ BookSubject }}</div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <label>Kitap seçiniz.</label>
                <select class="form-control" v-model="selectBookId" @change="onBookNameChange()">
                  <option v-for="b in BookNameList" :key="b" v-bind:value="b.id">{{ b.title }}</option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-4" v-show="BookLevelList.length>0">
                <label>Seviye seçiniz</label>
                <select class="form-control" v-model="selectBookLevelId">
                  <option v-for="l in BookLevelList" :key="l" v-bind:value="l.id">{{ l.id==0?'Tüm Seviyeler':l.title }}</option>
                </select>
              </div>
              <div class="col-8" v-show="BookSubjectList.length>0">
                <label>Konu seçiniz.</label>
                <select class="form-control"  v-model="selectBookSubjectId">
                  <option v-for="s in BookSubjectList" :key="s" v-bind:value="s.id">{{ s.title }}</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-check">
                  <input type="radio" class="form-check-input" v-model="changeType" v-bind:id="'ChangeTypeOne'" value="ChangeOneLesson" />
                  <label class="form-check-label" v-bind:for="'ChangeTypeOne'">Sadece {{LessonDate}} tarihli dersin konusunu değiştir.</label>
                </div>
                <div class="form-check">
                  <input type="radio" class="form-check-input"  v-model="changeType" v-bind:id="'ChangeTypeAll'" value="ChangeAllLesson"/>
                  <label class="form-check-label" v-bind:for="'ChangeTypeAll'">{{LessonDate}} ve sonrası derslerin konularını değiştir.</label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="BookChange()" :disabled="submitLoading">
                <span v-show="submitLoading" class="spinner-border spinner-border-sm" ></span>
                <span class="ml-2">Güncelle</span>
            </button>
          </div>
        </div>
      </div>
    </div>
</template>
<style scoped>
  
</style>
<script>
import {mapActions} from "vuex";
import {DateFormat} from "../../../helpers/date-convert-helper";
import {IsShowErrorMessage} from "../../../helpers/error-message-helper";
export default {
  name: 'BookChangeUnitModal',
  emits:['OpenCloseBookChangeUnitModal','RefreshSchedule'],
  components: {  },
  props: {
    Visible:{
      type:Boolean,
      default:false
    },
    ChangeBookInfo:{
      type:Object,
      default:null
    },
    ModalName:{
      type:String,
      default:'BookChangeUnitModal'
    }
  },
  data() {
    return {
      submitLoading:false,
      selectBookId:0,
      selectBookLevelId:-1,
      selectBookSubjectId:'',
      changeType:'ChangeOneLesson',
      bookList:[]
    }
  },
  computed:{
    TrainingFileId(){
      if(!this.ChangeBookInfo){
        return '';
      }
      return this.ChangeBookInfo.TrainingFileId;
    },
    MemberId(){
      if(!this.ChangeBookInfo){
        return '';
      }
      return this.ChangeBookInfo.MemberId;
    },
    LessonDate(){
      if(!this.ChangeBookInfo){
        return '';
      }
      return DateFormat(this.ChangeBookInfo.Date,'dd.mm.yyyy');
    },
    BookName(){
      if(!this.ChangeBookInfo){
        return '';
      }
      return this.ChangeBookInfo.Book;
    },
    BookSubject(){
      if(!this.ChangeBookInfo){
        return '';
      }
      return this.ChangeBookInfo.Subject;
    },
    BookNameList(){
      if(!this.bookList || this.bookList.length==0)
      {
        return [];
      }
      let book = this.bookList.map((x)=>{return {id:x.book.bookNameId,title:x.book.title}});
      return book;
    },
    BookLevelList(){
      if(this.selectBookId==0)
      {
        return [];
      }
      let selectId = this.selectBookId;
      let book = this.bookList.filter(x=>x.book.bookNameId==selectId);
      let levelListUniqueByKey=[];
      if(book){
        let levelList = book[0].subjects.map((x)=>{return{id:x.bookLevelId,title:x.levelTitle}});
        if(levelList){
          levelListUniqueByKey = [...new Map(levelList.map(item => [item['id'], item])).values()];
        }
      }
      return levelListUniqueByKey;
    },
    BookSubjectList(){
      if(this.selectBookId==0 || this.selectBookLevelId==-1)
      {
        return [];
      }
      let selectId = this.selectBookId;
      let book = this.bookList.filter(x=>x.book.bookNameId==selectId);
      let subjectList=[];
      if(book){
        let selectLevelId = this.selectBookLevelId;
        let level = book[0].subjects.filter(x=>x.bookLevelId==selectLevelId);
        if(level){
            subjectList = level.map((x)=>{return{id:x.id,title:x.subject}})
        }
      }
      return subjectList;
    }
  },
  methods:{
    ...mapActions(["TRAINING_GETBOOKWITHSUBJECT","TRAINING_CHANGEBOOKINSCHEDULEFORPANEL"]),
    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
      onBookNameChange(){
        this.selectBookLevelId=-1;
        this.selectBookSubjectId='';
      },
      onCloseBookChangeUnitModal(){
        window.$("#"+this.ModalName).modal('hide');
        this.$emit("OpenCloseBookChangeUnitModal", false);
      },
      BookChange(){
        if(this.selectBookSubjectId=='')
        {
          alert("Kitap seçimi yapınız.");
          return;
        }

        this.submitLoading=true;

        if(!confirm('Bu işlemi yapmak istiyor musunuz?'))
        {
          this.submitLoading=false;
          return;
        }
        this.TRAINING_CHANGEBOOKINSCHEDULEFORPANEL({
          memberId: this.MemberId,
          trainingFileId: this.TrainingFileId,
          lessonDate: DateFormat(this.ChangeBookInfo.Date,'yyyy-mm-dd'),
          bookName: this.selectBookId,
          bookSubjectId: this.selectBookSubjectId,
          isChangeOneLesson: (this.changeType==='ChangeOneLesson')
          }
        )
        .then((res)=>{
            let message = res.data.description;
            this.$toast.success(message,{
                  position : 'top',
                  duration : 3000
            });
            this.submitLoading=false;
            this.$emit("RefreshSchedule", false);
            this.onCloseBookChangeUnitModal();
        })
        .catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.submitLoading=false;
        });
      },
      GetBookWithLevel() {
        this.TRAINING_GETBOOKWITHSUBJECT()
        .then((res)=>{
              this.bookList = res.data;
              this.SelectOldBook();
          }).catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
          });
      },
      SelectOldBook(){
        if(!this.ChangeBookInfo){
          return '';
        }

        let bookOldId = this.ChangeBookInfo.BookId;
        var findBook = this.bookList.filter(x=>x.subjects.some(y=>y.id==bookOldId));
        if(findBook){
          this.selectBookId=findBook[0].book.bookNameId;
          let findSubject = findBook[0].subjects.filter(x=>x.id==bookOldId);
          if(findSubject){
            this.selectBookLevelId=findSubject[0].bookLevelId;
            this.selectBookSubjectId=findSubject[0].id;
          }
        }
      }
  },
  watch: {
    Visible(value){
      if(value){
        this.selectBookId=0;
        this.selectBookLevelId=-1;
        this.selectBookSubjectId='';
        this.changeType='ChangeOneLesson';
        if(this.bookList.length==0){
          this.GetBookWithLevel()
        }else{
          this.SelectOldBook();
        }
        window.$("#"+this.ModalName).modal({backdrop: 'static',keyboard: false, show: true});
      }
    }
  },
  mounted: function() {
  }
  
}
</script>