export const PERMISSONCODES={
    REPORT2:'44269971-9F87-4E8A-9028-47ED03D76974',//Raporlar sayfasına girebilir.
    REPORT3:'B34C28C0-D9A2-42B3-8E4F-23C6D824489E',//Eğitmen raporunu görebilir.
    REPORT4:'922748F4-655C-4BC5-8C4B-3207339295FA',//İşlemler raporunu görebilir.
    REPORT5:'A402A6A5-993E-4506-8931-222136D8364C',//Ödeme raporunu görebilir.
    REPORT6:'CCEE8B21-7A7A-40A5-A422-436132532A12',//Form raporunu görebilir.
    REPORT7:'9604D588-B86D-45D1-91A9-5E0970E29CA0',//Eğitmen ve öğrenci raporunu görebilir.
    TEACHER11:'DBED6C6D-C6C3-42C7-AC84-0802EBFF409A',//Öğretmen işlemleri sayfasına girebilir.
    USER12:'644F7651-F20B-4C02-A58E-26AD68EAEFD5',//Kullanıcı işlemleri sayfasına girebilir.
    FORM13:'BCC2D2C9-7636-4BB6-8AE3-4E83499C6BF4',//Form işlemleri sayfasına girebilir.
    STUDENT14:'F08F1E9D-243A-4875-A4ED-414CD457D2CD',//Öğrenci işlemleri sayfasına girebilir.
    STUDENT17:'1d5a8fe4-7b7f-45e0-9a69-37f54f2461e6',//Öğrenci Profili / Öğrenci Eğitim Paketi / Eğitim Durumu Değişikliği
    STUDENT18:'9a2e530e-4273-4a5f-9bbf-397bbc067880',//Öğrenci Profili / Ders Programı / Ünite Değişikliği
    STUDENT19:'39346bd0-34d0-4461-8825-dcd42662c941',//Öğrenci Profili / Şifre Değişikliği
};
