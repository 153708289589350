<template>
  <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Öğretmen İşlemleri</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{path:'/'}">Anasayfa</router-link>
              </li>
              <li class="breadcrumb-item active">Öğretmen İşlemleri</li>
            </ol>
          </div> <!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <button type="button" class="btn btn-sm btn-default" @click="OpenCreateModal()">
                  <i class="nav-icon fas fa-user"></i>
                  Eğitmen Oluştur
                </button>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th>Ad / Soyad</th>
                      <th width="150">Nickname</th>
                      <th width="120">Skype Adresi</th>
                      <th width="70">Cinsiyet</th>
                      <th width="70">Durum</th>
                      <th width="100">&nbsp;</th>
                      <th width="120">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr><td colspan="6" class="text-center" v-show="dataLoading">Yükleniyor...</td></tr>
                    <tr v-for="(item,index) in items" :key="item.id">
                      <td><a href="javascript:;" @click="OpenProfileModal(index)">{{item.name}} {{item.surname}}</a></td>
                      <td>{{item.nickname}}</td>
                      <td>{{item.skypeName}}</td>
                      <td class="text-center">{{GetGender(item.gender)}}</td>
                      <td class="text-center">
                        <span class="badge bg-success" v-if="item.status==1">Aktif</span>
                        <span class="badge bg-danger" v-else-if="item.status==0">Pasif</span>
                      </td>
                      <td>
                         <button type="button" class="btn btn-sm btn-default mr-1" v-if="item.status==1" @click="ChangeUserStatus(item.id,0)" :disabled="statusBtnDisabled"> 
                            Pasif Yap
                          </button>
                          <button type="button" class="btn btn-sm btn-default mr-1" v-else-if="item.status==0" @click="ChangeUserStatus(item.id,1)" :disabled="statusBtnDisabled">
                            Aktif Yap
                          </button>
                      </td>
                      <td>
                         <button type="button" class="btn btn-sm btn-success mr-1" v-if="item.isIncludePaymentReport==1" @click="ChangeUserPaymentReport(item.id,0)" :disabled="statusBtnDisabled"> 
                            Rapora Dahil
                          </button>
                          <button type="button" class="btn btn-sm btn-danger mr-1" v-else-if="item.isIncludePaymentReport==0" @click="ChangeUserPaymentReport(item.id,1)" :disabled="statusBtnDisabled">
                            Rapora Dahil Değil
                          </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <!-- /.card -->
                <div class="card-footer clearfix" v-show="totalPages>0">
                  <ul class="pagination pagination-sm m-0 float-right">
                    <li class="page-item" v-show="(currentPage-1)>0"><a class="page-link" href="#" @click="GetTeachers(currentPage-1)">&laquo;</a></li>
                    <li v-for="page in totalPages" :key="page" class="page-item" :class="{'active':page==currentPage}"><a class="page-link" href="#" @click="GetTeachers(page)">{{page}}</a></li>
                    <li class="page-item" v-show="(currentPage+1)<=totalPages"><a class="page-link" href="#" @click="GetTeachers(currentPage+1)">&raquo;</a></li>
                  </ul>
                </div>
            </div>
          </div>
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content -->


    
    <!-- Modal TeacherProfileModal -->
    <div class="modal fade" id="TeacherProfileModal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{selectedTeacher.name}} {{selectedTeacher.surname}} ({{selectedTeacher.nickname}})</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-3">
                          <!-- <div class="col-3">
                            <img class="profile-user-img img-fluid img-circle" src="../../../assets/default.png" alt="User profile picture"/>
                          </div> -->
                        <div class="row">
                          <div class="col-12">
                            <small>{{selectedTeacher.id}}</small>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <label>Cinsiyet :</label>
                            {{GetGender(selectedTeacher.gender)}}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <label>Skype :</label>
                            {{selectedTeacher.skypeName}}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <label>Saat Dilimi :</label>
                            Turkey Standard Time
                          </div>
                        </div>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-9">
                    
                    <div class="card card-primary card-outline card-outline-tabs">
                      <div class="card-header p-0 border-bottom-0">
                        <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                          <li class="nav-item">
                            <a class="nav-link active" id="profile-edit-tab" data-toggle="pill" href="#profile-edit" @click="OpenTabProfileEdit()"
                              role="tab" aria-controls="profile-edit" aria-selected="true">Profil</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" id="work-tab" data-toggle="pill" href="#work" @click="OpenTabWork()"
                              role="tab" aria-controls="work" aria-selected="false">Çalışma Saatleri</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" id="contact-tab" data-toggle="pill" href="#contact"  @click="OpenTabContact()"
                              role="tab" aria-controls="contact" aria-selected="false">İletişim</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" id="bank-tab" data-toggle="pill" href="#bank" @click="OpenTabBank()"
                              role="tab" aria-controls="bank" aria-selected="false">Ücret</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" id="payment-tab" data-toggle="pill" href="#payment" @click="OpenTabPayment()"
                              role="tab" aria-controls="payment" aria-selected="false">Ödeme</a>
                          </li>
                        </ul>
                      </div>
                      <div class="card-body">
                        <div class="tab-content" id="custom-tabs-four-tabContent">
                          <div class="tab-pane fade show active" id="profile-edit" role="tabpanel" aria-labelledby="profile-edit-tab">
                            <div class="overlay-wrapper">
                              <div class="overlay" v-show="selectedTeacher.profileTab.showLoading"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                                <div class="text-bold pt-2">Loading...</div>
                              </div>
                              <div class="row">
                                <div class="col-sm-12 col-md-6 col-lg-6">
                                  <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Ad" v-model="selectedTeacher.profileTab.name">
                                  </div>
                                  <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Soyad" v-model="selectedTeacher.profileTab.surname">
                                  </div>
                                  <div class="form-group">
                                    <select class="form-control" v-model="selectedTeacher.profileTab.gender">
                                      <option value="" selected="true"></option>
                                      <option value="1">Erkek</option>
                                      <option value="2">Kadın</option>
                                    </select>
                                  </div>
                                  <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Doğum Tarihi" v-model="selectedTeacher.profileTab.birthday" v-maska="'####-##-##'">
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-6">
                                  <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Kullanıcı Adı" v-model="selectedTeacher.profileTab.username">
                                  </div>
                                  <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Nickname" v-model="selectedTeacher.profileTab.nickname">
                                  </div>
                                  <div class="form-group">
                                    <select class="form-control">
                                      <option value="1" selected="true">Turkey Standard Time</option>
                                    </select>
                                  </div>
                                  <div class="form-group">
                                    <select class="form-control" v-model="selectedTeacher.profileTab.status">
                                      <option value="" selected="true"></option>
                                      <option value="0">Pasif</option>
                                      <option value="1">Aktif</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-12">
                                  <div class="form-group">
                                    <button type="button" class="btn btn-primary" :disabled="submitLoading" @click="onTeacherProfileUpdate()">
                                      <span v-show="submitLoading" class="spinner-border spinner-border-sm"></span>
                                      <span class="ml-2" >Kaydet</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                          </div>
                          </div>
                          <div class="tab-pane fade" id="work" role="tabpanel" aria-labelledby="work-tab">
                            <div class="overlay" v-if="selectedTeacher.workTab.showLoading"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                              <div class="text-bold pt-2">Loading...</div>
                            </div>
                            <div v-else>
                              <div class="row mb-3">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                  <div class="form-check">
                                    <input type="checkbox" id="AllHours" class="form-check-input" ref="AllHours" @click="AllHourSelect()" >
                                    <label class="form-check-label" for="AllHours">Tüm saat aralıklarını seç</label>
                                  </div>
                                </div>
                              </div>
                              <div class="row border-bottom mb-3" v-for="t in selectedTeacher.workTab.timeRange" :key="t">
                                <div class="col-sm-12 col-md-1 col-lg-1 bg-gray">
                                  <div class="form-check">
                                    <input type="checkbox" v-bind:id="t.key" class="form-check-input" v-bind:ref="'h'+t.key" @click="HourSelect(t.key)" >
                                    <label class="form-check-label" v-bind:for="t.key">{{t.time}}</label>
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-11 col-lg-11">
                                  <div class="row">
                                    <div class="col-sm-12 col-md-2 col-lg-2" v-for="m in t.minutes" :key="m">
                                      <div class="form-check">
                                        <input type="checkbox" v-bind:id="m.key" :checked="m.isAvailable" :disabled="m.isLock" v-bind:ref="'m'+m.key" @click="MinuteSelect(t.key,m.key)" class="form-check-input" >
                                        <label class="form-check-label" v-bind:for="m.key" v-html="m.title"></label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <button type="button" class="btn btn-primary float-right" :disabled="selectedTeacher.workTab.submitLoading" @click="SaveAvailableHours()">
                                <span v-show="selectedTeacher.workTab.submitLoading" class="spinner-border spinner-border-sm" ></span>
                                <span class="md-2" >Kaydet</span>
                              </button>
                            </div>
                          </div>
                          <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                            <div class="overlay" v-show="selectedTeacher.contactTab.showLoading"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
                                <div class="text-bold pt-2">Loading...</div>
                              </div>
                            <div class="row">
                              <label class="col-12">İletişim Bilgileri</label>
                              <div class="col-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" v-model="selectedTeacher.contactTab.skypeName" placeholder="Skype">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" v-model="selectedTeacher.contactTab.phoneNumber" placeholder="Telefon">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" v-model="selectedTeacher.contactTab.emailAddress" placeholder="E-Posta Adresi">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" v-model="selectedTeacher.contactTab.address" placeholder="Adres" disabled="true">
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-group">
                                    <button type="button" class="btn btn-primary" :disabled="submitLoading" @click="onTeacherContactUpdate()">
                                      <span v-show="submitLoading" class="spinner-border spinner-border-sm"></span>
                                      <span class="ml-2" >Kaydet</span>
                                    </button>
                                </div>
                              </div>
                            </div>
                            
                            <!-- <table class="table">
                              <thead>
                                <tr>
                                  <th>İletişim Bilgisi</th>
                                  <th style="width:120px">İletişim Türü</th>
                                  <th style="width:100px">Durumu</th>
                                  <th style="width:50px"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>enisyavas@gmail.com</td>
                                  <td>E-Posta</td>
                                  <td>Kullanılan</td>
                                  <td>
                                    <button class="btn btn-sm btn-default">Sil</button>
                                  </td>
                                </tr>
                                <tr>
                                  <td>enisyavas</td>
                                  <td>Skype</td>
                                  <td>Kullanılan</td>
                                  <td>
                                    <button class="btn btn-sm btn-default">Sil</button>
                                  </td>
                                </tr>
                                
                              </tbody>
                            </table> -->
                          </div>
                          <div class="tab-pane fade" id="bank" role="tabpanel" aria-labelledby="bank-tab">
                            <div class="row">
                              <label class="col-sm-12 col-md-3 col-lg-3">Saatlik Ücret Bilgisi</label>
                              <div class="col-sm-8 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <input type="text" class="form-control" v-model="selectedTeacher.bankTab.wage" placeholder="0.00" v-maska="'###.##'">
                                </div>
                              </div>
                              <div class="col-sm-12 col-md-6 col-lg-6">
                                <div class="form-group">
                                  <button type="button" class="btn btn-primary" :disabled="submitLoading" @click="onTeacherWageAdd()">
                                    <span v-show="submitLoading" class="spinner-border spinner-border-sm"></span>
                                    <span class="ml-2" >Ekle</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="overflow-auto" style="height:250px">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th>Başlangıç</th>
                                    <th>Bitiş</th>
                                    <th style="width:150px">Tutar</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="w in selectedTeacher.bankTab.wageList" :key="w">
                                    <td>{{DateFormat(w.startDate)}}</td>
                                    <td>{{w.finishDate==null?'-':DateFormat(w.finishDate)}}</td>
                                    <td>{{w.wage.toFixed(2)}} ₺</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="tab-pane fade" id="payment" role="tabpanel" aria-labelledby="payment-tab">
                            <div class="row">
                              <div class="col-sm-12 col-md-6 col-lg-6">
                                <label class="col-12">Hesaplanacak Tarih Aralığı</label>
                                <v-date-picker v-model="selectedTeacher.paymentTab.rangeDate" mode="date" is-range>
                                    <template v-slot="{ inputValue, inputEvents }">
                                      <div class="row">
                                        <div class="col-4">
                                          <div class="form-group">
                                            <input
                                                :value="inputValue.start"
                                                v-on="inputEvents.start"
                                                class="form-control form-control-sm"
                                              />
                                          </div>
                                        </div>
                                        <div class="col-4">
                                          <div class="form-group">
                                            <input
                                                :value="inputValue.end"
                                                v-on="inputEvents.end"
                                                class="form-control form-control-sm"
                                              />
                                          </div>
                                        </div>
                                        <div class="col-4">
                                          <div class="form-group">
                                              <button class="btn btn-sm btn-default" :disabled="selectedTeacher.paymentTab.btnCalculateLoading" @click="CalculateAmountForTeacher()">
                                                <span v-show="selectedTeacher.paymentTab.btnCalculateLoading" class="spinner-border spinner-border-sm mr-2"></span>
                                                <span>Hesapla</span>
                                              </button>
                                          </div>
                                        </div>
                                      </div>
                                    </template>
                                </v-date-picker>  
                              </div>
                              <div class="col-sm-12 col-md-6 col-lg-6">
                                <div v-if="selectedTeacher.paymentTab.btnCalculateLoading" class="text-center">Lütfen bekleyin...</div>
                                <div class="border p-2 mb-2" v-if="selectedTeacher.paymentTab.summaryZone.isShow">
                                  <div class="row">
                                    <div class="col-6"><strong>Ödeme Özeti</strong></div>
                                    <div class="col-3"><button class="btn btn-sm btn-default" @click="SaveTeacherPayment()" :disable="selectedTeacher.paymentTab.summaryZone.btnSaveLoading">Kaydet</button></div>
                                    <div class="col-3"><button class="btn btn-sm btn-default" @click="ShowPaymentDetail('calcDetail','')">Listele</button></div>
                                  </div>
                                  
                                  <div class="row border-bottom pt-1 pb-1">
                                    <div class="col-8"><i class="fa fa-check"></i>&nbsp;&nbsp;<small>{{paymentSummarySuccessLesson.count}} Adet</small></div>
                                    <div class="col-4"><small>{{paymentSummarySuccessLesson.amount.toFixed(2)}}₺</small></div>
                                  </div>
                                  <div class="row border-bottom pt-1 pb-1">
                                    <div class="col-8"><i class="fa fa-times"></i>&nbsp;&nbsp;<small>{{paymentSummaryCancelLesson.count}} Adet</small></div>
                                    <div class="col-4"><small>{{paymentSummaryCancelLesson.amount.toFixed(2)}}₺</small></div>
                                  </div>
                                  <div class="row border-bottom pt-1 pb-1">
                                    <div class="col-7">
                                      <input type="text" class="form-control form-control-sm" v-model="selectedTeacher.paymentTab.summaryZone.extraPriceDescription" />
                                    </div>
                                    <div class="col-5">
                                      <div class="input-group input-group-sm">
                                        <input type="text" class="form-control" v-model="selectedTeacher.paymentTab.summaryZone.extraPrice" />
                                        <div class="input-group-prepend">
                                          <span class="input-group-text">₺</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-8"><strong>Toplam Tutar</strong></div>
                                    <div class="col-4">{{paymentSummaryTotalAmount.toFixed(2)}}₺</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>Tarih Aralığı</th>
                                  <th style="width:110px" class="text-center">Tutar</th>
                                  <th style="width:140px" class="text-center">Durum</th>
                                  <th style="width:70px"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="p in selectedTeacher.paymentTab.paymentList" :key="p">
                                  <td>
                                    <span>{{DateFormatAloneDate(p.startDate)}}</span> - 
                                    <span>{{DateFormatAloneDate(p.finishDate)}}</span>
                                  </td>
                                  <td class="text-center">{{p.totalPrice.toFixed(2)}}₺</td>
                                  <td class="text-center">
                                    <button class="btn btn-sm btn-default" v-if="!p.isPaid" @click="DoPaidTeacherPayment(p.id)">Ödeme Yapıldı</button>
                                    <div v-if="p.isPaid">
                                      <div><small>Ödeme Yapıldı</small></div>
                                      <div><small>({{DateFormat(p.paymentDate)}})</small></div>
                                    </div>
                                  </td>
                                  <td>
                                    <button class="btn btn-sm btn-default" @click="ShowPaymentDetail('listDetail',p.detail)">Detay</button>
                                    <!-- <button class="btn btn-sm btn-default">Sil</button> -->
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal TeacherCreateModal -->
    <div class="modal fade" id="TeacherCreateModal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Öğretmen Oluştur</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">

              <div class="row">
                    <div class="col-12">
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Ad / Soyad</label>
                        <div class="col-sm-4">
                          <input type="text" class="form-control" v-model="createForm.name"/>
                        </div>
                        <div class="col-sm-4">
                          <input type="text" class="form-control" v-model="createForm.surname"/>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Nickname / Skype</label>
                        <div class="col-sm-4">
                          <input type="text" class="form-control" v-model="createForm.nickname"/>
                        </div>
                        <div class="col-sm-4">
                          <input type="text" class="form-control" v-model="createForm.skypeAddress"/>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label">D.Tarih / Cinsiyet</label>
                        <div class="col-sm-5">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="far fa-calendar-alt"></i></span>
                            </div>
                            <input type="text" class="form-control" v-model="createForm.birthday" v-maska="'####-##-##'"/>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <select class="form-control" v-model="createForm.gender">
                            <option value=""></option>
                            <option value="1">Erkek</option>
                            <option value="2">Kadın</option>
                          </select>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Kul. Adı / Şifre</label>
                        <div class="col-sm-5">
                          <input type="text" class="form-control" v-model="createForm.username"/>
                        </div>
                        <div class="col-sm-3">
                          <input type="password" class="form-control" v-model="createForm.password"/>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label" >Telefon No</label>
                        <div class="col-sm-8">
                          <input type="text" class="form-control" v-model="createForm.phoneNumber"/>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label" >E-Posta Adresi</label>
                        <div class="col-sm-8">  
                          <input type="text" class="form-control" v-model="createForm.emailAddress"/>
                        </div>
                      </div>
                      
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label" >Durum</label>
                        <!-- <div class="col-sm-2">  
                          <input type="text" class="form-control" v-model="createForm.workBeginTime" v-maska="'##:##'"/>
                        </div>
                        <div class="col-sm-2">  
                          <input type="text" class="form-control" v-model="createForm.workFinishTime" v-maska="'##:##'"/>
                        </div> -->
                        <div class="col-sm-8">
                          <select class="form-control" v-model="createForm.status">
                            <option value=""></option>
                            <option value="1">Aktif</option>
                            <option value="0">Pasif</option>
                          </select>
                        </div>
                      </div>
                    </div>
              </div>

            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary" :disabled="submitLoading" @click="onCreateTeacher()">
                <span v-show="submitLoading" class="spinner-border spinner-border-sm"></span>
                <span class="ml-2" >Kaydet</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal PaymentDetail -->
      <div class="modal fade" id="PaymentDetailModal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
          <div class="modal-content">
            <div class="modal-body p-0">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="text-center">Ders Tarihi</th>
                      <th class="text-center">Açıklama</th>
                      <th class="text-center">Ders Süresi</th>
                      <th class="text-center">Ders Sonucu</th>
                      <th>Tutar</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="l in selectedTeacher.paymentTab.paymentDetail" :key="l">
                      <td>{{DateFormatAloneDate(l.Date)}}</td>
                      <td class="text-center">{{l.Description}}</td>
                      <td class="text-center" v-if="l.PaymentType==1">{{l.Duration}} Dk</td>
                      <td class="text-center" v-if="l.PaymentType==1">{{ScheduleStatusText(l.Status)}}</td>
                      <td class="text-center" v-if="l.PaymentType==2">-</td>
                      <td class="text-center" v-if="l.PaymentType==2">-</td>
                      <td>{{l.Price.toFixed(2)}}₺</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="4" class="text-right"><strong>Toplam : </strong></td>
                      <td><strong>{{ paymentDetailTotal.toFixed(2) }}₺</strong></td>
                    </tr>
                  </tfoot>
                </table>
            </div>
          </div>
        </div>
      </div>

</template>

<script>
import {mapActions} from "vuex"
import {GetGenderText,GetScheduleStatusText} from "../../../helpers/enum-convert-helper"
import {CalculateTime} from "../../../helpers/date-convert-helper";
import {DateFormat} from "../../../helpers/date-convert-helper"
export default {
  name: "Teacher",
  components: {},
  data(){
    return{
      currentPage:0,
      items:[],
      pageSize:0,
      totalItems:0,
      totalPages:0,
      dataLoading:false,
      submitLoading: false,
      statusBtnDisabled:false,
      createForm:{
        name:'',
        surname:'',
        nickname:'',
        birthday:'1985-01-01',
        gender:'',
        username:'',
        password:'',
        status:'',
        emailAddress:'',
        phoneNumber:'0090',
        skypeAddress:'',
        workBeginTime:'07:00',
        workFinishTime:'23:50'
      },
      selectedTeacher:{
        id:'',
        name:'',
        surname:'',
        nickname:'',
        skypeName:'',
        phoneNumber:'',
        emailAddress:'',
        address:'',
        gender:'',
        workFinishTime:'',
        workStartTime:'',
        image:'',
        profileTab:{
          showLoading:false,
          name:'',
          surname:'',
          gender:'',
          username:'',
          nickname:'',
          birthday:'',
          status:''
        },
        workTab:{
          showLoading:true,
          submitLoading:false,
          enableHours:['2100','0500','0600','0700','0800','0900','1000','1100','1200','1300','1400','1500','1600','1700','1800','1900','2000'],
          timeRange : [],
        },
        contactTab:{
          showLoading:false,
          skypeName:'',
          phoneNumber:'',
          emailAddress:'',
          address:''
        },
        bankTab:{
          showLoading:false,
          wage:0,
          wageList:[]
        },
        paymentTab:{
          showLoading:false,
          rangeDate:{
            start:'',
            end:''
          },
          btnCalculateLoading:false,
          summaryZone:{
            isShow:false,
            btnSaveLoading:false,
            extraPriceDescription:'Prim Ödemesi',
            extraPrice:0.00,
          },
          paymentDetail:[],
          paymentSummary:[],
          paymentList:[]
        },
      },
      
    }
  },
  computed: {
    paymentSummarySuccessLesson(){
      if(this.selectedTeacher.paymentTab.paymentSummary.length==0)
        return {
          count:0,
          amount:0
        };
      
      let count = 0;
      let amount = 0;
      let lessons = this.selectedTeacher.paymentTab.paymentSummary.filter(x=>x.Status==2);  
      if(lessons){
        lessons.forEach(x => {
          count++;
          amount +=x.Price; 
        });
      }
      return {
        count:count,
        amount:amount
      };
    },
    paymentSummaryCancelLesson(){
      if(this.selectedTeacher.paymentTab.paymentSummary.length==0)
        return {
          count:0,
          amount:0
        };

        let count = 0;
        let amount = 0;
        let lessons = this.selectedTeacher.paymentTab.paymentSummary.filter(x=>x.Status==1);  
        if(lessons){
          lessons.forEach(x => {
            count++;
            amount +=x.Price; 
          });
        }
        return {
          count:count,
          amount:amount
        };
    },
    paymentSummaryTotalAmount(){
      let extraPrice=0;
      if(this.selectedTeacher.paymentTab.summaryZone.extraPrice>0){
        extraPrice=this.selectedTeacher.paymentTab.summaryZone.extraPrice;
      }

      return this.paymentSummarySuccessLesson.amount + this.paymentSummaryCancelLesson.amount + parseFloat(extraPrice);
    },

    paymentDetailTotal(){
        if(!this.selectedTeacher.paymentTab.paymentDetail)
          return 0;

        let total = 0;
        this.selectedTeacher.paymentTab.paymentDetail.forEach(x => {
          total += x.Price;
        });

        return total;
    }
  },
  created() {
    this.GetTeachers(1);
  },
  methods: {
    ...mapActions(["DO_ACTIVE","DO_INACTIVE","CREATE_TEACHER","TEACHER_LIST","TEACHER_PROFILE_UPDATE","TEACHER_WORKTIME_UPDATE","TEACHER_CONTACT_UPDATE",
    "TEACHER_GETWAGEBYTEACHERID","TEACHER_ADDWAGE",
    "GET_TEACHER_PAYMENT","CALCULATE_PRICE_BY_TEACHER_ID","ADD_TEACHER_PAYMENT","DO_PAID_TEACHER_PAYMENT","TEACHER_GETAVAILABLEHOURSBYID",
    "TEACHER_SAVEAVAILABLEHOURSBYID","DO_INCLUDEREPORT","DO_EXCLUDEREPORT"
  ]),
    GetGender(value){
      return GetGenderText(value);
    },
    OpenCreateModal(){
      window.$("#TeacherCreateModal").modal('show');
    },
    OpenProfileModal(index){
      let teacher = this.items[index];

      this.selectedTeacher.id = teacher.id;
      this.selectedTeacher.name = teacher.name;
      this.selectedTeacher.surname = teacher.surname;
      this.selectedTeacher.nickname = teacher.nickname;
      this.selectedTeacher.skypeName = teacher.skypeName;
      this.selectedTeacher.gender = teacher.gender;
      this.selectedTeacher.image = '';

      this.selectedTeacher.profileTab.name = teacher.name;
      this.selectedTeacher.profileTab.surname = teacher.surname;
      this.selectedTeacher.profileTab.nickname = teacher.nickname;
      this.selectedTeacher.profileTab.gender = teacher.gender;
      this.selectedTeacher.profileTab.username = teacher.username;
      this.selectedTeacher.profileTab.birthday = teacher.birthday;
      this.selectedTeacher.profileTab.status = teacher.status;

      this.selectedTeacher.workTab.timeRange=[];

      this.selectedTeacher.contactTab.skypeName = teacher.skypeName;
      this.selectedTeacher.contactTab.phoneNumber = teacher.phoneNumber;
      this.selectedTeacher.contactTab.emailAddress = teacher.eMailAddress;

      this.selectedTeacher.paymentTab.showLoading=false;
      this.selectedTeacher.paymentTab.rangeDate={start:'',end:''};
      this.selectedTeacher.paymentTab.btnCalculateLoading=false;
      this.selectedTeacher.paymentTab.summaryZone.isShow=false;
      this.selectedTeacher.paymentTab.summaryZone.btnSaveLoading=false;
      this.selectedTeacher.paymentTab.summaryZone.extraPriceDescription='Prim Ödemesi';
      this.selectedTeacher.paymentTab.summaryZone.extraPrice=0;
      this.selectedTeacher.paymentTab.paymentSummary=[];
      this.selectedTeacher.paymentTab.paymentList=[];
      this.selectedTeacher.paymentTab.paymentDetail=[];
      window.$('#profile-edit-tab').click();
      window.$("#TeacherProfileModal").modal('show');
    },

    OpenTabProfileEdit(){
      
    },
    OpenTabWork(){
      this.GetAvailableHours(this.selectedTeacher.id);
    },
    OpenTabContact(){
      
    },
    OpenTabBank(){
      this.selectedTeacher.bankTab.showLoading=true;
      let teacherId = this.selectedTeacher.id;
      this.GetTeacherWage(teacherId);
    },
    OpenTabPayment(){
      this.selectedTeacher.paymentTab.showLoading=false;
      this.selectedTeacher.paymentTab.rangeDate={start:'',end:''};
      this.selectedTeacher.paymentTab.btnCalculateLoading=false;
      this.selectedTeacher.paymentTab.summaryZone.isShow=false;
      this.selectedTeacher.paymentTab.summaryZone.btnSaveLoading=false;
      this.selectedTeacher.paymentTab.summaryZone.extraPriceDescription='Prim Ödemesi';
      this.selectedTeacher.paymentTab.summaryZone.extraPrice=0;
      this.selectedTeacher.paymentTab.paymentSummary=[];
      this.selectedTeacher.paymentTab.paymentList=[];
      this.selectedTeacher.paymentTab.paymentDetail=[];

      this.GetTeacherPaymentList(this.selectedTeacher.id);
    },
    onTeacherProfileUpdate(){
      this.submitLoading = true;
      let errMessage='';
      if(!errMessage && !this.selectedTeacher.profileTab.name)
        errMessage='Ad giriniz.';
      
      if(!errMessage && !this.selectedTeacher.profileTab.surname)
        errMessage='Soyad giriniz.';
      
      if(!errMessage && !this.selectedTeacher.profileTab.nickname)
        errMessage='Nickname giriniz.';

      if(!errMessage && !this.selectedTeacher.profileTab.birthday)
        errMessage='Doğum tarihini giriniz.';

      if(!errMessage && !this.selectedTeacher.profileTab.gender)
        errMessage='Cinsiyet seçiniz.';

      if(!errMessage && !this.selectedTeacher.profileTab.status)
        errMessage='Durumunu seçiniz.';


      if(errMessage){
        this.$toast.error(errMessage,{position : 'top',duration : 3000});
        this.submitLoading = false;
        return;
      }

      this.TEACHER_PROFILE_UPDATE({
            id:this.selectedTeacher.id,
            name: this.selectedTeacher.profileTab.name,
            surname : this.selectedTeacher.profileTab.surname,
            nickname : this.selectedTeacher.profileTab.nickname,
            birthday : new Date(this.selectedTeacher.profileTab.birthday).toISOString(),
            gender:parseInt(this.selectedTeacher.profileTab.gender),
            status:parseInt(this.selectedTeacher.profileTab.status),
        }
        )
      .then(()=>{
          
        this.selectedTeacher.name = this.selectedTeacher.profileTab.name;
        this.selectedTeacher.surname = this.selectedTeacher.profileTab.surname;
        this.selectedTeacher.nickname = this.selectedTeacher.profileTab.nickname;
        this.selectedTeacher.gender = this.selectedTeacher.profileTab.gender;
          let message = "Profil bilgileri başarıyla kaydedildi.";
          this.$toast.success(message,{
                position : 'top',
                duration : 3000
            });
          this.submitLoading = false;
      })
      .catch(error=>{
            let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            this.$toast.error(message,{
                position : 'top',
                duration : 3000
            });
            this.submitLoading=false;
        });

    },
    onTeacherWageAdd(){
      this.submitLoading = true;
      let errMessage='';
      if(!errMessage && !this.selectedTeacher.bankTab.wage)
        errMessage='Ücret tutarını giriniz.';
      
      if(errMessage){
        this.$toast.error(errMessage,{position : 'top',duration : 3000});
        this.submitLoading = false;
        return;
      }

      if(!confirm('Saatlik ücreti eklemek istiyor musunuz?')){
        this.submitLoading = false;
        return;
      }

      this.TEACHER_ADDWAGE({
          teacherId:this.selectedTeacher.id,
          wage: this.selectedTeacher.bankTab.wage
        }
      )
      .then(()=>{
          let message = "Saatlik ücret kaydedildi.";
          this.$toast.success(message,{
                position : 'top',
                duration : 3000
            });
          this.submitLoading = false;
          this.GetTeacherWage(this.selectedTeacher.id);
          this.selectedTeacher.bankTab.wage=0;
      })
      .catch(error=>{
            let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            this.$toast.error(message,{
                position : 'top',
                duration : 3000
            });
            this.submitLoading=false;
        });
    },
    onTeacherContactUpdate(){
      this.submitLoading = true;
      let errMessage='';
      if(!errMessage && !this.selectedTeacher.contactTab.skypeName)
        errMessage='Skype adresi giriniz.';
      
      if(!errMessage && !this.selectedTeacher.contactTab.phoneNumber)
        errMessage='Telefon numarası giriniz.';
      
        if(!errMessage && !this.selectedTeacher.contactTab.emailAddress)
        errMessage='E-Posta adresini giriniz.';
      
      if(errMessage){
        this.$toast.error(errMessage,{position : 'top',duration : 3000});
        this.submitLoading = false;
        return;
      }

      this.TEACHER_CONTACT_UPDATE({
            id:this.selectedTeacher.id,
            skypeName: this.selectedTeacher.contactTab.skypeName,
            phoneNumber : this.selectedTeacher.contactTab.phoneNumber,
            emailAddress : this.selectedTeacher.contactTab.emailAddress,
            address : this.selectedTeacher.contactTab.address,
        }
        )
      .then(()=>{
          this.selectedTeacher.skypeName = this.selectedTeacher.contactTab.skypeName;
          this.selectedTeacher.phoneNumber = this.selectedTeacher.contactTab.phoneNumber;
          this.selectedTeacher.emailAddress = this.selectedTeacher.contactTab.emailAddress;
          this.selectedTeacher.address = this.selectedTeacher.contactTab.address;

          let message = "İletişim bilgileri başarıyla kaydedildi.";
          this.$toast.success(message,{
                position : 'top',
                duration : 3000
            });
          this.submitLoading = false;
      })
      .catch(error=>{
            let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            this.$toast.error(message,{
                position : 'top',
                duration : 3000
            });
            this.submitLoading=false;
        });
    },
    onCreateTeacher() {
      this.submitLoading = true;
      let errMessage='';
      if(!errMessage && !this.createForm.name)
        errMessage='Ad giriniz.';
      
      if(!errMessage && !this.createForm.surname)
        errMessage='Soyad giriniz.';
      
      if(!errMessage && !this.createForm.nickname)
        errMessage='Nickname giriniz.';

      if(!errMessage && !this.createForm.birthday)
        errMessage='Doğum tarihini giriniz.';

      if(!errMessage && !this.createForm.gender)
        errMessage='Cinsiyet seçiniz.';

      if(!errMessage && !this.createForm.username)
        errMessage='Kullanıcı adını giriniz.';

      if(!errMessage && !this.createForm.password)
        errMessage='Şifre giriniz.';
      
      if(!errMessage && !this.createForm.status)
        errMessage='Durumunu seçiniz.';

      if(!errMessage && !this.createForm.emailAddress)
        errMessage='E-Posta adresini giriniz.';

      if(!errMessage && !this.createForm.phoneNumber)
        errMessage='Telefon numarasını giriniz.';

      if(!errMessage && !this.createForm.skypeAddress)
        errMessage='Skype adresini giriniz.';

      // if(!errMessage && !this.createForm.workBeginTime)
      //   errMessage='Başlangış saatini giriniz.';

      // if(!errMessage && !this.createForm.workFinishTime)
      //   errMessage='Bitiş saatini giriniz.';

        
      if(errMessage){
        this.$toast.error(errMessage,{position : 'top',duration : 3000});
        this.submitLoading = false;
        return;
      }

      this.CREATE_TEACHER({
            name: this.createForm.name,
            surname : this.createForm.surname,
            nickname : this.createForm.nickname,
            birthday : new Date(this.createForm.birthday).toISOString(),
            gender:parseInt(this.createForm.gender),
            username : this.createForm.username,
            password:this.createForm.password,
            status:parseInt(this.createForm.status),
            emailAddress:this.createForm.emailAddress,
            phoneNumber:this.createForm.phoneNumber,
            skypeAddress:this.createForm.skypeAddress,
            //workBeginTime:this.createForm.workBeginTime,
            //workFinishTime:this.createForm.workFinishTime
        }
        )
      .then(()=>{
          window.$("#TeacherProfileModal").modal('hide');
          this.createForm.name='';
          this.createForm.surname='';
          this.createForm.nickname='';
          this.createForm.birthday='1985-01-01';
          this.createForm.gender='';
          this.createForm.username='';
          this.createForm.password='';
          this.createForm.status='';
          this.createForm.emailAddress='';
          this.createForm.phoneNumber='0090';
          this.createForm.skypeAddress='';
          //this.createForm.workBeginTime='07:00';
          //this.createForm.workFinishTime='23:50';

          let message = "Kullanıcı başarıyla oluşturuldu.";
          this.$toast.success(message,{
                position : 'top',
                duration : 3000
            });
          this.GetTeachers(0);

      })
      .catch(error=>{
            let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            this.$toast.error(message,{
                position : 'top',
                duration : 3000
            });
            this.submitLoading=false;
        });
    },
    GetTeachers(page) {
      this.dataLoading=true;
      if(!page) {  page=1; }
      
      this.TEACHER_LIST({page})
      .then((res)=>{
            this.currentPage=res.data.currentPage;
            this.items = res.data.items;
            this.pageSize=res.data.pageSize;
            this.totalItems=res.data.totalItems;
            this.totalPages=res.data.totalPages;
            this.dataLoading=false;
        }).catch(error=>{
            let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            this.$toast.error(message,{
                position : 'top',
                duration : 3000
            });
            this.dataLoading=false;
        });
    },
    GetTeacherWage(teacherId){
      this.TEACHER_GETWAGEBYTEACHERID({teacherId})
      .then((res)=>{
            this.selectedTeacher.bankTab.wageList=res.data;
            this.selectedTeacher.bankTab.showLoading=false;
        }).catch(error=>{
            let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            this.$toast.error(message,{
                position : 'top',
                duration : 3000
            });
            this.selectedTeacher.bankTab.showLoading=false;
        });
    },
    ChangeUserPaymentReport(userId,status){
        this.statusBtnDisabled=true;
        if(status){
          this.DO_INCLUDEREPORT({userId})
          .then(()=>{
              this.items.forEach(item => {
                if(item.id==userId){
                  item.isIncludePaymentReport = status;
                }
              });
              this.statusBtnDisabled=false;
          })
          .catch(error=>{
              let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
              this.$toast.error(message,{
                position : 'top',
                duration:3000
              });
              this.statusBtnDisabled=false;
          });
        }else{
            this.DO_EXCLUDEREPORT({userId})
            .then(()=>{
                this.items.forEach(item => {
                  if(item.id==userId){
                    item.isIncludePaymentReport = status;
                  }
                });
                this.statusBtnDisabled=false;
            })
            .catch(error=>{
                let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                this.$toast.error(message,{
                  position : 'top',
                  duration:3000
                });
                this.statusBtnDisabled=false;
            });
        }
    },
    
    ChangeUserStatus(userId,status){
        this.statusBtnDisabled=true;
        if(status==1){
          this.DO_ACTIVE({userId})
          .then(()=>{
              this.items.forEach(item => {
                if(item.id==userId){
                  item.status = status;
                }
              });
              this.statusBtnDisabled=false;
          })
          .catch(error=>{
              let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
              this.$toast.error(message,{
                position : 'top',
                duration:3000
              });
              this.statusBtnDisabled=false;
          });
        }else{
            this.DO_INACTIVE({userId})
            .then(()=>{
                this.items.forEach(item => {
                  if(item.id==userId){
                    item.status = status;
                  }
                });
                this.statusBtnDisabled=false;
            })
            .catch(error=>{
                let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                this.$toast.error(message,{
                  position : 'top',
                  duration:3000
                });
                this.statusBtnDisabled=false;
            });
        }
    },
    CalculateTimeByOffset(value){
        if(!value) 
          return "";

        return CalculateTime(value);
    },
    CalculateAmountForTeacher(){
        this.selectedTeacher.paymentTab.btnCalculateLoading=true;
        let teacherId = this.selectedTeacher.id;
        let beginDate = this.selectedTeacher.paymentTab.rangeDate.start;
        let finishDate = this.selectedTeacher.paymentTab.rangeDate.end;
        this.CALCULATE_PRICE_BY_TEACHER_ID({teacherId,beginDate,finishDate})
        .then((res)=>{
            this.selectedTeacher.paymentTab.btnCalculateLoading=false;
            this.selectedTeacher.paymentTab.paymentSummary=JSON.parse(res.data);
            this.selectedTeacher.paymentTab.summaryZone.isShow=true;
          }).catch(error=>{
              let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
              this.$toast.error(message,{
                  position : 'top',
                  duration : 3000
              });
              this.selectedTeacher.paymentTab.btnCalculateLoading=false;
          });
    },
    GetTeacherPaymentList(teacherId){
      this.selectedTeacher.paymentTab.showLoading=true;
      this.GET_TEACHER_PAYMENT({teacherId})
      .then((res)=>{
            this.selectedTeacher.paymentTab.paymentList=res.data;
            this.selectedTeacher.paymentTab.showLoading=false;
        }).catch(error=>{
            let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            this.$toast.error(message,{
                position : 'top',
                duration : 3000
            });
            this.selectedTeacher.paymentTab.showLoading=false;
        });
    },
    FormatTime(value){
      if(value.length!=8)
        return '';
      
      let startTime = this.CalculateTimeByOffset(value.substring(0,4).substring(0,2)+':'+value.substring(0,4).substring(2,4));
      let finishTime = this.CalculateTimeByOffset(value.substring(4,8).substring(0,2)+':'+value.substring(4,8).substring(2,4));
      return startTime + '-' + finishTime;
    },
    GetAvailableHours(teacherId){
      this.selectedTeacher.workTab.showLoading=true;
      this.TEACHER_GETAVAILABLEHOURSBYID(teacherId)
      .then((res)=>{
          let time = res.data;
          let format = time.map((x)=>({key:x.timeKey,title:this.FormatTime(x.timeKey),isAvailable:x.isAvailable,isLock:x.isLock}));
          for(var i=0;i<this.selectedTeacher.workTab.enableHours.length;i++){
            let eHour = this.selectedTeacher.workTab.enableHours[i];
            this.selectedTeacher.workTab.timeRange.push({time:this.CalculateTimeByOffset(eHour.substring(0,2)+':'+eHour.substring(2,4)),key:eHour,minutes:format.filter(x=>x.key.substring(0,2)===eHour.substring(0,2))});
          }
          this.selectedTeacher.workTab.showLoading=false;
        }).catch(error=>{
          this.selectedTeacher.workTab.showLoading=false;
          let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          this.$toast.error(message,{
              position : 'top',
              duration : 3000
          });
        });
    },
    AllHourSelect(){
        let status = this.$refs.AllHours.checked;
        this.selectedTeacher.workTab.timeRange.forEach(h => {
          h.minutes.forEach(m=>{
            if(!m.isLock){
              m.isAvailable=status
            }
          });
        });
    },
    HourSelect(value){
      let status = this.$refs['h'+value].checked;
      let selectedHour = this.selectedTeacher.workTab.timeRange.find(x=>x.key===value);
      selectedHour.minutes.forEach(m => {
        if(!m.isLock){
          m.isAvailable=status
        }
      });
    },
    MinuteSelect(hourValue,minuteValue){
      let status = this.$refs['m'+minuteValue].checked;
      let selectedHour = this.selectedTeacher.workTab.timeRange.find(x=>x.key===hourValue);
      let selectMinute = selectedHour.minutes.find(m => m.key===minuteValue);
      selectMinute.isAvailable = status;
    },
    SaveAvailableHours(){
      let mainThis = this;
      let teacherId = mainThis.selectedTeacher.id;
      mainThis.selectedTeacher.workTab.submitLoading=true;
      if(!confirm('Çalışma saatleri kaydedilsin mi?')){
        mainThis.selectedTeacher.workTab.submitLoading=false;
        return;
      }

      let model = {teacherId:teacherId,hours:[]};
      mainThis.selectedTeacher.workTab.timeRange.forEach(t => {
        t.minutes.forEach(m=>{
          model.hours.push({TimeKey:m.key,IsAvailable:m.isAvailable});
        });
      });
      mainThis.TEACHER_SAVEAVAILABLEHOURSBYID(model)
      .then((res)=>{
        mainThis.$toast.success(res.data.description,{
              position : 'top',
              duration:3000
          });
          mainThis.selectedTeacher.workTab.submitLoading=false;
        }).catch(error=>{
            let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            this.$toast.error(message,{
                position : 'top',
                duration : 3000
            });
        });
    },
    ShowPaymentDetail(view,listDetailData){
      switch (view) {
        case 'calcDetail':
          this.selectedTeacher.paymentTab.paymentDetail = this.selectedTeacher.paymentTab.paymentSummary;
          break;
        case 'listDetail':
          this.selectedTeacher.paymentTab.paymentDetail = JSON.parse(listDetailData);
          break;
        default:
          this.selectedTeacher.paymentTab.paymentDetail = [];
          break;
      }
      window.$("#PaymentDetailModal").modal('show');
    },
    SaveTeacherPayment(){
      let thisMain = this;

      if(!confirm('Bu hesaplamayı kaydetmek istiyor musunuz?'))
        return;

      this.selectedTeacher.paymentTab.summaryZone.btnSaveLoading=true;
      
      if(this.selectedTeacher.paymentTab.summaryZone.extraPrice>0){
        let extrePrice={
          paymentType:2,
          description:this.selectedTeacher.paymentTab.summaryZone.extraPriceDescription,
          trainingFileId:'00000000-0000-0000-0000-000000000000',
          date:this.selectedTeacher.paymentTab.rangeDate.start,
          status:0,
          duration:0,
          price:this.selectedTeacher.paymentTab.summaryZone.extraPrice
        };
        this.selectedTeacher.paymentTab.paymentSummary.push(extrePrice);
      }


      let model={
        teacherId:this.selectedTeacher.id,
        startDate:this.selectedTeacher.paymentTab.rangeDate.start,
        finishDate:this.selectedTeacher.paymentTab.rangeDate.end,
        detail:this.selectedTeacher.paymentTab.paymentSummary,
        totalPrice:this.paymentSummaryTotalAmount
      };

      this.ADD_TEACHER_PAYMENT(model)
      .then(()=>{
            this.selectedTeacher.paymentTab.summaryZone.btnSaveLoading=false;
            let message = "Ödeme başarıyla kaydedildi.";
            this.$toast.success(message,{
                position : 'top',
                duration : 3000
            });
            thisMain.GetTeacherPaymentList(thisMain.selectedTeacher.id);
        }).catch(error=>{
            let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            this.$toast.error(message,{
                position : 'top',
                duration : 3000
            });
            this.selectedTeacher.paymentTab.summaryZone.btnSaveLoading=false;
        });

    },
    DoPaidTeacherPayment(id){
      let thisMain = this;

      if(!confirm('Bu kaydı ödendi olarak kaydetmek istiyor musunuz?'))
        return;
      let model={
        id:id,
        teacherId:this.selectedTeacher.id,
      };

      this.DO_PAID_TEACHER_PAYMENT(model)
      .then(()=>{
            let message = "Ödendi olarak kaydedildi.";
            this.$toast.success(message,{
                position : 'top',
                duration : 3000
            });
            thisMain.GetTeacherPaymentList(thisMain.selectedTeacher.id);
        }).catch(error=>{
            let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            this.$toast.error(message,{
                position : 'top',
                duration : 3000
            });
        });
    },
    DateFormat(value){
      return DateFormat(value,'dd.mm.yyyy hh:MM:ss');
    },
    DateFormatAloneDate(value){
      return DateFormat(value,'dd.mm.yyyy');
    },
    ScheduleStatusText(value){
      return GetScheduleStatusText(value);
    },
  },
};
</script>
<style scoped>
.error-feedback { color: red;}
</style>