import { UserList, DoActive, DoInactive, Create, GetUser,UpdateProfile,ChangePassword,CreateTeacher,TeacherList,TeacherProfileUpdate,
    TeacherWorkTimeUpdate,TeacherContactUpdate,ActiveTeacherList,TeacherAddWage,GetWageByTeacherId,
    GetTeacherPaymentByTeacherId,CalculatePriceByTeacherId,AddTeacherPayment,DoPaidTeacherPayment,GetAvailableHoursById,SaveAvailableHoursById,
    GetAvailableHoursAllTeacher,DoExcludedReport,DoIncludedReport,IncludedPaymentReporTeacherList,ActiveUserList,GetUsersInQueue,
    DoInactiveDistrubuteForBaseOperator,DoInactiveDistrubuteForBaseSale,DoInactiveDistrubuteForLevelTestSale,
    DoActiveDistrubuteForBaseOperator,DoActiveDistrubuteForBaseSale,DoActiveDistrubuteForLevelTestSale
} from "../services/user.service";

const user_module = {
    state:{},
    getters:{},
    mutations:{},
    actions:{
        USER_LIST : (context,model) =>{
            return new Promise((resolve,reject)=>{
                UserList(model.page)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        DO_INCLUDEREPORT: (context,model) =>{
            return new Promise((resolve,reject)=>{
                DoIncludedReport(model.userId)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        DO_EXCLUDEREPORT: (context,model) =>{
            return new Promise((resolve,reject)=>{
                DoExcludedReport(model.userId)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        DO_ACTIVE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                DoActive(model.userId)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        DO_INACTIVE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                DoInactive(model.userId)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        CREATE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                Create(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        CREATE_TEACHER : (context,model) =>{
            return new Promise((resolve,reject)=>{
                CreateTeacher(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TEACHER_PROFILE_UPDATE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                TeacherProfileUpdate(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TEACHER_WORKTIME_UPDATE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                TeacherWorkTimeUpdate(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TEACHER_CONTACT_UPDATE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                TeacherContactUpdate(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        
        TEACHER_GETWAGEBYTEACHERID : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetWageByTeacherId(model.teacherId)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },

        TEACHER_ADDWAGE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                TeacherAddWage(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },

        TEACHER_LIST : (context,model) =>{
            return new Promise((resolve,reject)=>{
                TeacherList(model.page)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TEACHER_ACTIVELIST : () =>{
            return new Promise((resolve,reject)=>{
                ActiveTeacherList()
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        USER_ACTIVELIST : () =>{
            return new Promise((resolve,reject)=>{
                ActiveUserList()
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        
        TEACHER_INCLUDEDTEACHERLIST : () =>{
            return new Promise((resolve,reject)=>{
                IncludedPaymentReporTeacherList()
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TEACHER_GETAVAILABLEHOURSBYID : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetAvailableHoursById(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TEACHER_SAVEAVAILABLEHOURSBYID : (context,model) =>{
            return new Promise((resolve,reject)=>{
                SaveAvailableHoursById(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        GET_USER : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetUser(model.userId)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        UPDATE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                UpdateProfile(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        CHANGE_PASSWORD : (context,model) =>{
            return new Promise((resolve,reject)=>{
                ChangePassword(model.userId)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        GET_TEACHER_PAYMENT : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetTeacherPaymentByTeacherId(model.teacherId)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        
        
        CALCULATE_PRICE_BY_TEACHER_ID : (context,model) =>{
            return new Promise((resolve,reject)=>{
                CalculatePriceByTeacherId(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        ADD_TEACHER_PAYMENT : (context,model) =>{
            return new Promise((resolve,reject)=>{
                AddTeacherPayment(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        DO_PAID_TEACHER_PAYMENT : (context,model) =>{
            return new Promise((resolve,reject)=>{
                DoPaidTeacherPayment(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TEACHER_GETAVAILABLEHOURSALLTEACHER : () =>{
            return new Promise((resolve,reject)=>{
                GetAvailableHoursAllTeacher()
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },

        TEACHER_GETUSERINQUEUE : () =>{
            return new Promise((resolve,reject)=>{
                GetUsersInQueue()
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },

        USER_INACTIVEDISTRUBUTEFORBASEOPERATOR : (context,model) =>{
            return new Promise((resolve,reject)=>{
                DoInactiveDistrubuteForBaseOperator(model.userId)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        USER_INACTIVEDISTRUBUTEFORBASESALE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                DoInactiveDistrubuteForBaseSale(model.userId)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        USER_INACTIVEDISTRUBUTEFORBASETESTSALE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                DoInactiveDistrubuteForLevelTestSale(model.userId)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },






        USER_ACTIVEDISTRUBUTEFORBASEOPERATOR : (context,model) =>{
            return new Promise((resolve,reject)=>{
                DoActiveDistrubuteForBaseOperator(model.userId)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        USER_ACTIVEDISTRUBUTEFORBASESALE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                DoActiveDistrubuteForBaseSale(model.userId)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        USER_ACTIVEDISTRUBUTEFORBASETESTSALE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                DoActiveDistrubuteForLevelTestSale(model.userId)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
    }
}

export default user_module;