import { 
    MemberOldListWithPage,
    MemberNewListWithPage,
    MemberPrepareListWithPage,
    MemberWaitingListWithPage,
    MemberDateListWithPage,
    MemberCancelListWithPage,
    MemberSearchListWithPage,
    MemberFormCancel,
    MemberFormPrepare,
    MemberNoteListWithPage,
    MemberSaveNote,
    MemberInfoUpdate,
    MemberContactEmailUpdate,
    MemberContactPhoneUpdate,
    GetOperationUserList,
    GetOperationUserListForChangeUser,
    MemberFormOperatorUpdate,
    MemberSalesPersonUpdate,
    MemberStudentOperatorUpdate,
    MemberActiveStudentListWithPage,
    MemberComplatedStudentListWithPage,
    MemberInActiveStudentListWithPage,
    MemberFreezeEducationStudentListWithPage,
    MemberFindTeacherStudentListWithPage,
    MemberBreakTimeStudentListWithPage,
    MemberStudentSearchListWithPage,
    FormRegister,
    AddReferenceNumber,
    GetReasonForCancellation,
    GetReasonForDelay,
    GetBillInfos,
    CreatBillInfo,
    GetTransactionListWithPage,
    ImportFormWithCsv,
    GetMemberForProfileModal,
    MemberTryYourSelfStudentListWithPage,
    MemberChangePasswordForPanel
 } from "../services/member.service";

const member_module = {
    state:{},
    getters:{},
    mutations:{},
    actions:{
        FORM_IMPORTCVS : (context,model) =>{
            return new Promise((resolve,reject)=>{
                ImportFormWithCsv(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_OLDLIST : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberOldListWithPage(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_NEWLIST : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberNewListWithPage(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_PREPARELIST : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberPrepareListWithPage(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_DATELIST : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberDateListWithPage(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_CANCELLIST : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberCancelListWithPage(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        
        MEMBER_SEARCHLIST : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberSearchListWithPage(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_FORMCANCEL : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberFormCancel(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_FORMPREPARE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberFormPrepare(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_WAITINGLIST : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberWaitingListWithPage(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_NOTELIST : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberNoteListWithPage(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_SAVENOTE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberSaveNote(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_INFOSAVE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberInfoUpdate(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_EMAILSAVE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberContactEmailUpdate(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        }, 
        MEMBER_PHONESAVE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberContactPhoneUpdate(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_GETOPERATIONUSERS : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetOperationUserList(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_GETOPERATIONUSERSFORCHANGEUSER : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetOperationUserListForChangeUser(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_FORMOPERATORSAVE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberFormOperatorUpdate(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_SALESAVE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberSalesPersonUpdate(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_STUDENTOPERATORSAVE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberStudentOperatorUpdate(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_ACTIVESTUDENTLIST : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberActiveStudentListWithPage(model.page)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_COMPLATEDSTUDENTLIST : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberComplatedStudentListWithPage(model.page)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_TRYYOURSELFLIST : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberTryYourSelfStudentListWithPage(model.page)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_INACTIVESTUDENTLIST : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberInActiveStudentListWithPage(model.page)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_FREEZEEDUCATIONSTUDENTLIST : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberFreezeEducationStudentListWithPage(model.page)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_FINDTEACHERSTUDENTLIST : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberFindTeacherStudentListWithPage(model.page)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_BREAKTIMESTUDENTLIST : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberBreakTimeStudentListWithPage(model.page)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_SEARCHSTUDENTLIST : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberStudentSearchListWithPage(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_REGISTERFORM: (context,model) =>{
            return new Promise((resolve,reject)=>{
                FormRegister(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_REFERENCENUMBERSAVE: (context,model) =>{
            return new Promise((resolve,reject)=>{
                AddReferenceNumber(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },

        MEMBER_GETREASONFORCANCELLATION : () =>{
            return new Promise((resolve,reject)=>{
                GetReasonForCancellation()
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_GETREASONFORDELAY : () =>{
            return new Promise((resolve,reject)=>{
                GetReasonForDelay()
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_GETBILLS: (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetBillInfos(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_CREATEBILL: (context,model) =>{
            return new Promise((resolve,reject)=>{
                CreatBillInfo(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_TRANSACTIONLIST : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetTransactionListWithPage(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_GETMEMBERFORPROFILEMODAL : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetMemberForProfileModal(model.id)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBER_MEMBERCHANGEPASSWORDFORPANEL : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MemberChangePasswordForPanel(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        }
    }
}

export default member_module;