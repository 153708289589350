import { 
    GetMemberId,
    GetTabStatisticInitByMemberId,
    GetTabAccountingPaymentByMemberId,
    GetTabTaskByMemberId,
    GetTabFormHistoryByMemberId,
    GetTabTrainingByMemberId,
    GetBillingAddressMemberId,
    GetFreePaymentMemberId,
    GetPackageList,
    GetBookList,
    CreatePackage
 } from "../services/memberprofile.service";

const memberprofile_module = {
    state:{},
    getters:{},
    mutations:{},
    actions:{
        MEMBERPROFILE_GETMEMBER : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetMemberId(model.id)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBERPROFILE_TABSTATISTICINIT : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetTabStatisticInitByMemberId(model.id)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBERPROFILE_TABSACCOUNTINGPAYMENTINIT : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetTabAccountingPaymentByMemberId(model.id)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBERPROFILE_TABTASKINIT : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetTabTaskByMemberId(model.id)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBERPROFILE_FORMHISTORY : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetTabFormHistoryByMemberId(model.id)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBERPROFILE_TRAININGS : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetTabTrainingByMemberId(model.id)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBERPROFILE_GETPACKAGES : () =>{
            return new Promise((resolve,reject)=>{
                GetPackageList()
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBERPROFILE_GETBOOKS : () =>{
            return new Promise((resolve,reject)=>{
                GetBookList()
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBERPROFILE_BILLINGADDRESS : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetBillingAddressMemberId(model.id)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBERPROFILE_FREEPAYMENTS : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetFreePaymentMemberId(model.id)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MEMBERPROFILE_CREATEPACKAGE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                CreatePackage(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
    }
}

export default memberprofile_module;