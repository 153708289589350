<template>
   <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">{{DateFormatForListTitle(lessonDate)}} Tarihli Öğrenci Listesi</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->
    <!-- Main content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body p-0">
                <div class="table-responsive">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th style="min-width: 120px">Durum</th>
                      <th class="text-center" style="min-width: 100px">Ders Saati</th>
                      <th class="text-center" style="min-width: 80px">Süre</th>
                      <th style="min-width: 150px">Öğrenci</th>
                      <th class="text-center" style="min-width: 50px"></th>
                      <th class="text-center" style="min-width: 250px"></th>
                      <th class="text-center" style="min-width: 150px"></th>
                      <th class="text-center" style="min-width: 80px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="listLoading">
                      <td colspan="7" class="text-center">Yükleniyor...</td>
                    </tr>
                    <tr v-else-if="studentList.length==0">
                      <td colspan="7" class="text-center">Öğrenci Bulunamadı.</td>
                    </tr>
                    <tr v-else v-for="s in studentList.sort((a,b)=>{return parseInt(a.trainingFile.time.replace(':',''))-parseInt(b.trainingFile.time.replace(':',''));})" :key="s">
                      <td :class="{'text-success':s.lesson.status==2}">{{ScheduleStatusText(s.lesson==null?'':s.lesson.status)}}</td>  
                      <td class="text-center h4">{{CalculateTimeByOffset(s.trainingFile.time)}}</td>
                      <td class="text-center">{{s.trainingFile.duration}} Dk</td>
                      <td>
                        <div class="text-capitalize mb-1">Student {{s.student.name}}</div>
                        <div v-if="s.trainingFile.contactType==3 && (s.lesson==null || s.lesson.status==0)">{{ s.trainingFile.contactData }}</div>
                      </td>
                      <td>
                        <span class="badge bg-danger" v-if="s.trainingFile.isDemo">Demo</span>
                        <span class="badge mr-2 float-left badge-info" v-if="s.trainingFile.isTryYourself">Kendini Dene</span>
                      </td>
                      <td class="text-right">
                        <a v-if="s.trainingFile.contactType==3 && (s.lesson==null || s.lesson.status==0)" v-bind:href="'skype:'+s.trainingFile.contactData+'?call'" class="btn btn-sm btn-danger mr-2" target="_blank">Ara <i class="fa fa-phone"></i></a>
                        <button class="btn btn-sm btn-primary" @click="OpenLessonModal(s.student.id,s.trainingFile.id)">Ders Detayı İçin Tıklayın</button>
                      </td>
                      <td class="text-center">
                        <div class="progress mt-2">
                          <div class="progress-bar bg-success" style="width: {{s.statistic.rate}}%" aria-valuenow="{{s.statistic.rate}}" aria-valuemin="0" aria-valuemax="{{s.statistic.totalNumberOfLessons}}">%{{s.statistic.rate}}</div>
                        </div>
                      </td>
                      <td class="text-sm align-middle">{{s.statistic.numberOfLessonsRealized}}/{{s.statistic.totalNumberOfLessons}}</td>
                    </tr>
                  </tbody>
                </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content -->
    <!-- Modal LessonDetail -->
    <div class="modal fade" id="LessonModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-capitalize" id="exampleModalLongTitle">{{DateFormatForListTitle(DetailModalLessonDate)}} - {{DetailModalSubject}}</h5>
          </div>
            <div class="modal-body">
              <div class="alert alert-warning" role="alert">
                Merhaba değerli öğretmenimiz, Yeni sistem kuralları gereği öğrencilerinizle yapacağınız derslerin kayıt altına alınması gerektiğini hatırlatır, iyi dersler dileriz.
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-xl-6">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                        <div class="btn-group btn-group-sm">
                          <a class="btn btn-default" target="_blank" v-show="IsContent(DetailModalLessonBook,1)" @click="GetBook(DetailModalLessonBook,1)"><i class="fas fa-book-open"></i> Bölüm-1</a>
                          <a class="btn btn-default" target="_blank" v-show="IsContent(DetailModalLessonBook,2)" @click="GetBook(DetailModalLessonBook,2)"><i class="fas fa-book-open"></i> Bölüm-2</a>
                          <a class="btn btn-default" target="_blank" v-show="IsContent(DetailModalLessonBook,3)" @click="GetBook(DetailModalLessonBook,3)"><i class="fas fa-book-open"></i> Bölüm-3</a>
                          <a class="btn btn-default" target="_blank" v-show="IsContent(DetailModalLessonBook,6)" @click="GetBook(DetailModalLessonBook,6)"><i class="fas fa-book-open"></i> Bölüm-4</a>
                          <a class="btn btn-default" target="_blank" v-show="IsContent(DetailModalLessonBook,10)" @click="GetBook(DetailModalLessonBook,10)" ><i class="fas fa-comment-alt"></i> Diyalog-1</a>
                          <a class="btn btn-default" target="_blank" v-show="IsContent(DetailModalLessonBook,11)" @click="GetBook(DetailModalLessonBook,11)" ><i class="fas fa-comment-alt"></i> Diyalog-2</a>
                          <a class="btn btn-default" target="_blank" v-show="IsContent(DetailModalLessonBook,12)" @click="GetBook(DetailModalLessonBook,12)" ><i class="fas fa-comment-alt"></i> Diyalog-3</a>
                          <a class="btn btn-default" target="_blank" v-show="IsContent(DetailModalLessonBook,13)" @click="GetBook(DetailModalLessonBook,13)" ><i class="fas fa-book-open"></i> Okuma</a>
                          <a class="btn btn-default" target="_blank" @click="ShowOtherMaterial()" v-show="GetVideoLesson(DetailModalLessonBook)!=null || GetAudios(DetailModalLessonBook).length>0"><i class="fas fa-play"></i> Video ve Ses</a>
                        </div>
                      </div>
                      <div v-if="bookContent!=null">
                        <ZoomImage v-if="bookContent.bookUrl!=null" class="mt-2" v-bind:img-normal="bookContent.bookUrl"></ZoomImage>
                        <div class="mt-2 p-3" v-if="bookContent.contentHtml!=null">
                          <h3 v-if="bookContent.contentTitle!=null">{{ bookContent.contentTitle }}</h3>
                          <div class="dialog" v-html="bookContent.contentHtml"></div>
                        </div>
                      </div>
                      <div v-else-if="showOtherMaterial">

                        <div v-if="GetVideoLesson(DetailModalLessonBook)!=null" style=" width: 80%; margin: 25px auto 0px;">
                          <video ref="VideoPlayer" playsInline controls controlsList="nodownload noplaybackrate" style="width: 100%;height: 100%;object-fit:fill;" >
                            <source v-bind:src="GetVideoLesson(DetailModalLessonBook)" type="video/mp4">
                          </video>
                        </div>
                        <div v-else class="text-center" style="width: 100%;">Video hazırlanıyor.</div>

                        <div v-if="GetAudios(DetailModalLessonBook).length>0" style=" width: 70%; margin: 25px auto 0px;">
                          <div v-for="(a,i) in GetAudios(DetailModalLessonBook)" :key="i" >
                            <small class="text-body-tertiary" v-html="(a.title!=null)?a.title:'Kitap içindeki diyalogları dinleyebilirsiniz.'"></small>
                            <audio v-bind:ref="'AudioPlayer'" v-bind:src="a.url" controls controlsList="nodownload noplaybackrate" style="width:100%;" class="mt-1"></audio>
                          </div>
                        </div>
                        <div v-else class="text-center" style="width: 100%;">
                          Ses dosyası hazırlanıyor.
                        </div>
                        
                      </div>
                      
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-xl-6">
                  <div class="row mb-3">
                    <div class="col-sm-12 col-md-3 col-lg-3">
                      <label>Ders Durumu : </label>
                    </div>
                    <div class="col-sm-12 col-md-2 col-lg-2" v-if="DetailModalLessonStatus!=0">{{ScheduleStatusText(DetailModalLessonStatus)}}</div>
                    <div class="col-sm-12 col-md-2 col-lg-3" v-if="DetailModalLessonStatus==0">
                      <div class="form-group">
                        <div class="form-check">
                          <input type="radio" id="statusComplated" value="2" v-model="lessonResult.status" class="form-check-input" >
                          <label class="form-check-label" for="statusComplated">Derse Katıldı</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4" v-if="DetailModalLessonStatus==0">
                      <div class="form-group">
                        <div class="form-check">
                          <input type="radio" id="statusCancel" value="1" v-model="lessonResult.status" class="form-check-input" >
                          <label class="form-check-label" for="statusCancel">Derse Katılmadı</label>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div class="row" style="margin-bottom: 100px;" v-if="DetailModalLessonStatus==0 || DetailModalLessonStatus==2">
                    <div class="col-12">
                      <label>(*) Eğitmen Görüşü ve Tavsiyeleri</label>
                      <div style="height:120px" v-if="DetailModalLessonStatus==0 && DetailModalLessonResult==null">
                        <quill-editor v-model:value="lessonResult.note" :options="{modules:{toolbar:[['bold','italic','underline','strike'],[{align:[]}]]}}"/>
                        <span class="text-sm text-danger">Not : En az 150 karakter olması gerekli. Yorum ve tavsiyeler İngilizce yazılmalıdır.</span>
                      </div>
                      <div v-else-if="DetailModalLessonResult!=null" v-html="DetailModalLessonResult.note"></div>
                    </div>
                  </div>
                  <div class="row mb-3" v-if="DetailModalLessonStatus==0 || DetailModalLessonStatus==2">
                    <div class="col-12">
                      <label>(*) Yapılan hatalar ve doğruları</label>
                      <div v-if="DetailModalLessonStatus==0 && DetailModalLessonResult==null">
                        <div class="input-group mb-1" v-for="(c,index) in lessonResult.incorrectList" :key="c">
                          <div class="input-group-append">
                            <span class="input-group-text">{{ index+1 }}.</span>
                          </div>
                          <input type="text" class="form-control" placeholder="Hatayı yazınız" v-model="c.incorrect" />
                          <input type="text" class="form-control" placeholder="Doğrusunu yazınız"  v-model="c.correct">
                          <div class="input-group-prepend"><button class="btn btn-primary" @click="AddIncorrectList()" :disabled="lessonResult.incorrectList.length!=(index+1)">+</button></div>
                          <div class="input-group-prepend"><button class="btn btn-primary" @click="RemoveIncorrectList(index)" :disabled="index==0 || lessonResult.incorrectList.length!=(index+1)">-</button></div>
                        </div>
                      </div>
                      <div v-else-if="DetailModalLessonResult!=null">
                          <div class="row border-bottom">
                            <div class="col-6"><strong>Yanlış</strong></div>
                            <div class="col-6"><strong>Doğru</strong></div>
                          </div>
                          <div class="row" v-for="data in DetailModalIncorrects" :key="data">
                            <div class="col-6 text-danger">{{ data.InCorrect }}</div>
                            <div class="col-6 text-success">{{ data.Correct }}</div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3" v-if="DetailModalLessonStatus==0 || DetailModalLessonStatus==2">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <label>(*) Ders Sonu Değerlendirme</label>
                      <div class="table-responsive mb-3">
                        <table class="table mb-0">
                          <thead>
                            <tr>
                              <th class="text-sm">Dinleme</th>
                              <th class="text-sm">Okuma</th>
                              <th class="text-sm">Konuşma</th>
                              <th class="text-sm">Kelime B.</th>
                              <th class="text-sm">Dilbilgisi</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="DetailModalLessonStatus==0 && DetailModalLessonResult==null">
                              <td><select v-model="lessonResult.listening" class="form-control form-control-sm"><option value="5">A</option><option value="4">B</option><option value="3">C</option><option value="2">D</option><option value="1">E</option></select></td>
                              <td><select v-model="lessonResult.reading" class="form-control form-control-sm"><option value="5">A</option><option value="4">B</option><option value="3">C</option><option value="2">D</option><option value="1">E</option></select></td>
                              <td><select v-model="lessonResult.speaking" class="form-control form-control-sm"><option value="5">A</option><option value="4">B</option><option value="3">C</option><option value="2">D</option><option value="1">E</option></select></td>
                              <td><select v-model="lessonResult.vocabulary" class="form-control form-control-sm"><option value="5">A</option><option value="4">B</option><option value="3">C</option><option value="2">D</option><option value="1">E</option></select></td>
                              <td><select v-model="lessonResult.grammar" class="form-control form-control-sm"><option value="5">A</option><option value="4">B</option><option value="3">C</option><option value="2">D</option><option value="1">E</option></select></td>
                            </tr>

                            <tr v-else-if="DetailModalLessonResult!=null">
                              <td class="text-center h3">{{LessonPuan(DetailModalLessonResult.listening)}}</td>
                              <td class="text-center h3">{{LessonPuan(DetailModalLessonResult.reading)}}</td>
                              <td class="text-center h3">{{LessonPuan(DetailModalLessonResult.speaking)}}</td>
                              <td class="text-center h3">{{LessonPuan(DetailModalLessonResult.vocabulary)}}</td>
                              <td class="text-center h3">{{LessonPuan(DetailModalLessonResult.grammar)}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="row mb-3" v-if="IsLevelBook">
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <strong>(*) Öğrencinin Seviyesi :</strong>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-8" v-if="DetailModalLessonStatus==0 && DetailModalLessonResult==null">
                          <select v-model="lessonResult.levelTestLessonResult" class="form-control form-control-sm">
                            <option value="-1">&nbsp;</option>
                            <option value="0">No Level</option>
                            <option value="1">A1 - Başlangıç (Beginner)</option>
                            <option value="2">A2 - Temel (Elementary)</option>
                            <option value="3">B1 - Orta seviye öncesi (Pre-Intermediate)</option>
                            <option value="4">B2 - Orta seviye (Intermediate)</option>
                            <option value="5">C1 - Orta seviyenin üstü (Upper-Indermediate)</option>
                            <option value="6">C2 - İleri (Advanced)</option>
                          </select>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-8" v-else-if="DetailModalLessonResult!=null">
                          {{LevelTestResult(DetailModalLessonResult.levelTestLessonResult)}}
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <div class="text-bold" v-if="DetailModalBeforeLesson!=null">Önceki Dersin Konusu : ({{DateFormatForListTitle(DetailModalBeforeLesson.date)}})</div>  
                      <div class="mb-2" v-if="DetailModalBeforeLesson!=null">{{DetailModalBeforeLesson.bookTitle}}</div>
                      <div class="text-bold" v-if="DetailModalNextLesson!=null">Sonraki Dersin Konusu : ({{DateFormatForListTitle(DetailModalNextLesson.date)}})</div>
                      <div v-if="DetailModalNextLesson!=null">{{DetailModalNextLesson.bookTitle}}</div>
                    </div>
                  </div>
                  <span class="text-sm text-danger" v-if="DetailModalLessonStatus==0">(*) Doldurulması zorunlu alanlar.</span>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal" aria-label="Close" :disabled="modalSubmitLoading" @click="CloseLessonModal()">İptal</button>
              <button type="button" class="btn btn-primary" :disabled="modalSubmitLoading" @click="SaveLessonsResult()" v-if="DetailModalLessonStatus==0">
                 <span v-show="modalSubmitLoading"  class="spinner-border spinner-border-sm" ></span>
                 <span class="ml-2" >Kaydet</span>
              </button>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import {DateFormat,CalculateDateTime,CalculateTime} from "../../../helpers/date-convert-helper";
import {CreateLink} from "../../../helpers/link-create-helper";
import {IsShowErrorMessage} from "../../../helpers/error-message-helper";
import {GetScheduleStatusText,GetLessonPuanText,GetEnglishLevelText} from "../../../helpers/enum-convert-helper";
import { quillEditor } from 'vue3-quill';
import ZoomImage from '../../../components/ZoomImage.vue';
import {LEVELTESTBOOKID} from '../../../settings';
//import customQuillModule from 'customQuillModule'
//Quill.register('modules/customQuillModule', customQuillModule)

export default {
  name: "Students",
  components: {
    quillEditor,
    ZoomImage
  },
  data(){
    return {
      modalSubmitLoading:false,
      interval:null,
      time:null,
      lessonDate : new Date().toUTCString(),
      studentList:[],
      selectResult:{},
      lessonResult:{
        memberId:'',
        trainingFileId:'',
        date:'',
        note:'',
        incorrectList:[{correct:'',incorrect:''}],
        listening:0,
        reading:0,
        speaking:0,
        vocabulary:0,
        grammar:0,
        levelTestLessonResult:-1,
        status:0,
        bookUrl:''
      },
      listLoading:true,
      showOtherMaterial:false,
      bookContent:null
      // {
      //   bookUrl:null,
      //   contentHtml:null,
      //   contentTitle:null,
      // },
    }
  },
  computed:{
    IsLevelBook:function(){
      if(!this.selectResult)
        return false;

      if(!this.selectResult.book)
        return false;

      return LEVELTESTBOOKID==this.selectResult.book.id;
    },
    DetailModalSubject:function(){
      if(!this.selectResult)
        return '';
      if(!this.selectResult.book)
        return '';

      return this.selectResult.book.subject;
    },
    DetailModalLessonDate:function(){
      if(!this.selectResult)
        return '';

      return this.selectResult.date;
    },
    DetailModalLessonStatus:function(){
      if(!this.selectResult)
        return 0;

      if(!this.selectResult.status)
        return 0;

      return this.selectResult.status;
    },
    DetailModalLessonBook:function(){
      if(!this.selectResult)
        return null;

      if(!this.selectResult.book)
        return null;

      if(!this.selectResult.book.contents)
        return null;

      return this.selectResult.book.contents;
    },
    DetailModalBeforeLesson:function(){
      if(!this.selectResult)
        return null;

      if(!this.selectResult.beforeLesson)
        return null;

        return this.selectResult.beforeLesson;
    },
    DetailModalNextLesson:function(){
      if(!this.selectResult)
        return null;

      if(!this.selectResult.nextLesson)
        return null;

        return this.selectResult.nextLesson;
    },
    DetailModalLessonResult:function(){
      if(!this.selectResult)
        return null;

      if(!this.selectResult.result)
        return null;

      return this.selectResult.result;
    },
    DetailModalIncorrects:function(){
      if(!this.selectResult)
        return null;

      if(!this.selectResult.result)
        return null;

      if(!this.selectResult.result.inCorrects)
        return null;

      return JSON.parse(this.selectResult.result.inCorrects);
    },
  },
  methods:{
    ...mapActions(["TEACHER_GETSTUDENTSWITHCLASSTODAY","TEACHER_GETLESSONSFORRESULT","TEACHER_SAVELESSONSRESULT"]),
    OpenLessonModal(studentId,trainingFileId){
      this.lessonResult.memberId=studentId;
      this.lessonResult.trainingFileId=trainingFileId;
      this.lessonResult.date = DateFormat(this.lessonDate,'yyyy-mm-dd',true);
      this.selectResult={};
      this.TEACHER_GETLESSONSFORRESULT(trainingFileId)
      .then((res)=>{
            this.selectResult = res.data;
            if(this.IsContent(this.DetailModalLessonBook,1)){
              this.GetBook(this.DetailModalLessonBook,1);
            }else if(this.IsContent(this.DetailModalLessonBook,10)){
              this.GetBook(this.DetailModalLessonBook,10);
            }
            window.$('#LessonModal').modal({backdrop: 'static',keyboard: false, show: true});
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
        });
    },
    AddIncorrectList(){
      this.lessonResult.incorrectList.push({correct:'',incorrect:''});
    },
    RemoveIncorrectList(index){
      this.lessonResult.incorrectList.splice(index,1);
    },

    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
    CalculateDateTimeByOffset(value){
        return CalculateDateTime(value);
    },
    CalculateTimeByOffset(value){
        return CalculateTime(value);
    },
    DateFormatForListTitle(value){
      return DateFormat(value,'dd.mm.yyyy');
    },
    ScheduleStatusText(value){
      return GetScheduleStatusText(value);
    },
    LessonPuan(value){
      return GetLessonPuanText(value);
    },
    LevelTestResult(value){
      return GetEnglishLevelText(value);
    },
    IsContent(bookContents,contentType){
      if(bookContents){
          let book = bookContents.filter(x=>x.contentType==contentType)[0];
          if(book){
            return true;
          }
       }
       return false;
    },
    GetBookUrl(bookContents,contentType){
       if(bookContents){
          let book = bookContents.filter(x=>x.contentType==contentType)[0];
          if(book){
            return CreateLink(book.fileName);
          }
       }
       return null;
    },
    ShowOtherMaterial(){
        this.showOtherMaterial=true;
        this.bookContent=null;
    },
    GetBook(bookContents,contentType){
      this.showOtherMaterial=false;
      let contentHtml = '',contentTitle='';
      if(bookContents){
        let findBookContent = bookContents.filter(x=>x.contentType==contentType)[0];
        if(findBookContent){
          contentHtml = this.RenderDialog(findBookContent.contentHtml);
          contentTitle = findBookContent.contentTitle;
        }
      }

      this.bookContent = {
        bookUrl:this.GetBookUrl(bookContents,contentType),
        contentHtml : contentHtml,
        contentTitle: contentTitle,
      }
    },
    RenderDialog(value){
      let names = [
        {key:'Emma',class:'green-text'},{key:'Jack',class:'red-text'},
        {key:'Alice',class:'green-text'},{key:'Bob',class:'red-text'},
        {key:'Lisa',class:'green-text'},{key:'Tom',class:'red-text'},
        {key:'Mike',class:'green-text'},{key:'Sarah',class:'red-text'},
        {key:'John',class:'green-text'}
      ];
      let text = value;
      if(text){
        let name='',colorClass='';
        for (let i = 0; i < names.length; i++) {
          name = names[i].key + ':';
          colorClass = names[i].class
          text = text.replace(new RegExp(name,'g'),'<br><span class=\''+colorClass+'\'>'+name+'</span>');
        }
      }
      return text;
    },
    GetAudios:function(bookContents){


      let result=[];
      if(!bookContents){return result;}
      let contents = bookContents.filter(x=>x.fileType===4)
      if(contents.length==0){return result;}
      
      for (let i = 0; i < contents.length; i++) {
        result.push({url:process.env.VUE_APP_ROOT_API+"Source/book/audio?fileName="+contents[i].fileName,title:contents[i].fileTitle});
      }
      return result;
    },
    GetVideoLesson:function(bookContents){
      if(!bookContents){return null;}
      let content = bookContents.find(x=>x.fileType===5)
      if(!content){return null;}
      
      let url = process.env.VUE_APP_ROOT_API+"Source/book/video?fileName="+content.fileName;
      return url;
    },
    GetStudent(){
      this.listLoading=true;
      this.TEACHER_GETSTUDENTSWITHCLASSTODAY()
      .then((res)=>{
          this.listLoading =false;
          this.studentList = res.data;
        }).catch(error=>{
            this.listLoading=false;
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
        });
    },
    SaveLessonsResult(){
      this.modalSubmitLoading = true;
      if(!this.lessonResult.status){
          this.$toast.error('Ders durumunu seçmelisiniz.',{position : 'top',duration : 3000});
          this.modalSubmitLoading = false;
          return;
      }

      if(this.lessonResult.status==2){
        if(this.lessonResult.note.length<150){
          this.$toast.error('"Eğitmen Görüşü ve Tavsiyeleri" alanına en az 150 karakterlik bir yazı girmelisiniz.',{position : 'top',duration : 3000});
          this.modalSubmitLoading = false;
          return;
        }

        if(this.lessonResult.incorrectList.length==0){
          this.$toast.error('"Yapılan hatalar ve doğruları" bölümünü doldurmalısınız.',{position : 'top',duration : 3000});
          this.modalSubmitLoading = false;
          return;
        }else{
          let status=false;
          for (let index = 0; index < this.lessonResult.incorrectList.length; index++) {
            if(this.lessonResult.incorrectList[index].correct.length<3 || this.lessonResult.incorrectList[index].length<3){
              this.$toast.error((index+1) + '. bölümü boş bıraktınız.',{position : 'top',duration : 3000});
              status=true;
              break;
            }
          }
          if(status){
            this.modalSubmitLoading = false;
            return;
          }
        }

        if(this.lessonResult.listening==0 && this.lessonResult.reading==0 && this.lessonResult.speaking==0 && this.lessonResult.vocabulary==0 && this.lessonResult.grammar==0){
          this.$toast.error('"Ders Sonu Değerlendirme" bölümünden en az bir tane seçmelisiniz.',{position : 'top',duration : 3000});
          this.modalSubmitLoading = false;
          return;
        }

        if(this.IsLevelBook && this.lessonResult.levelTestLessonResult==-1){
          this.$toast.error('Öğrencinin İngilizce seviyesini giriniz.',{position : 'top',duration : 3000});
          this.modalSubmitLoading = false;
          return;
        }

      }
      
      if(!confirm("İşlemi kaydetmek istiyor musunuz?")){
        this.modalSubmitLoading = false;
        return;
      }
      this.TEACHER_SAVELESSONSRESULT(this.lessonResult)
      .then(()=>{
          this.modalSubmitLoading=false;

          this.lessonResult.memberId='';
          this.lessonResult.trainingFileId='';
          this.lessonResult.date='';
          this.lessonResult.note='';
          this.lessonResult.incorrectList=[{correct:'',incorrect:''}];
          this.lessonResult.listening=0;
          this.lessonResult.reading=0;
          this.lessonResult.speaking=0;
          this.lessonResult.vocabulary=0;
          this.lessonResult.grammar=0;
          this.lessonResult.status=0;
          this.lessonResult.levelTestLessonResult=-1;
          

          let message = "İşlem başarıyla gerçekleşti.";
          this.GetStudent();
          window.$("#LessonModal").modal('hide');
          this.$toast.success(message,{
                position : 'top',
                duration : 3000
            });
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.modalSubmitLoading=false;
        });
    },
    CloseLessonModal(){
      this.showOtherMaterial=false;
      this.showBookImage=true;
    }
  },
  mounted:function(){
      this.GetStudent();
  }
};
</script>
<style scoped>   
  .dialog ::v-deep .red-text { 
      color: #faa125;
      font-weight:bold;
  }
  .dialog ::v-deep .green-text { 
      color: #52b748;
      font-weight:bold;
  }
</style>