import {service} from "../helpers/request"

function GetMemberId(id){
    return service({
        url:'/MemberProfileModal/GetMemberById?id='+id,
        method:'post',
        data:{}
    })
}
function GetTabStatisticInitByMemberId(id){
    return service({
        url:'/MemberProfileModal/GetTabStatisticInitByMemberId?id='+id,
        method:'post',
        data:{}
    })
}
function GetTabAccountingPaymentByMemberId(id){
    return service({
        url:'/MemberProfileModal/GetTabAccountingPaymentByMemberId?id='+id,
        method:'post',
        data:{}
    })
}
function GetTabTaskByMemberId(id){
    return service({
        url:'/MemberProfileModal/GetTabTaskByMemberId?id='+id,
        method:'post',
        data:{}
    })
}
function GetTabFormHistoryByMemberId(id){
    return service({
        url:'/MemberProfileModal/GetTabFormHistoryByMemberId?id='+id,
        method:'post',
        data:{}
    })
}

function GetTabTrainingByMemberId(id){
    return service({
        url:'/MemberProfileModal/GetTabTrainingByMemberId?id='+id,
        method:'post',
        data:{}
    })
}
function GetBillingAddressMemberId(id){
    return service({
        url:'/MemberProfileModal/GetBillingAddress?id='+id,
        method:'post',
        data:{}
    })
}
function GetFreePaymentMemberId(id){
    return service({
        url:'/MemberProfileModal/GetFreePayment?id='+id,
        method:'post',
        data:{}
    })
}
function GetPackageList(){
    return service({
        url:'/MemberProfileModal/GetPackageList',
        method:'post',
        data:{}
    })
}

function GetBookList(){
    return service({
        url:'/MemberProfileModal/GetBookList',
        method:'post',
        data:{}
    })
}
function CreatePackage(model){
    return service({
        url:'/MemberProfileModal/CreatePackage',
        method:'post',
        data:model
    })
}

export {
    GetMemberId,
    GetTabStatisticInitByMemberId,
    GetTabAccountingPaymentByMemberId,
    GetTabTaskByMemberId,
    GetTabFormHistoryByMemberId,
    GetTabTrainingByMemberId,
    GetBillingAddressMemberId,
    GetFreePaymentMemberId,
    GetPackageList,
    GetBookList,
    CreatePackage
};
